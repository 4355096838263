import update from "immutability-helper";
import {iLanguage} from "services/Language";

export interface iLanguagesStore {
  languages: iLanguage[]
  languagesMap: { [key: string]: iLanguage }
  language: iLanguage | null
  languageID: string
  direction: number
  isRTL: boolean
  isInit: boolean
}

export const INITIAL_STATE = (): iLanguagesStore => ({
  languages: [],
  languagesMap: {},
  language: null,
  languageID: '',
  direction: 1,
  isRTL: false,
  isInit: false
});

export const language = (state = INITIAL_STATE(), action: any) => {
  const {type, payload}: any = action;
  switch (type) {
    case 'LANGUAGE_MERGE': {
      return update(state, {$merge: payload});
    }
    case 'LANGUAGE_SET_LANG': {
      const {languageID} = payload;
      const language = state.languagesMap[languageID]
      if (language) {
        document.getElementsByTagName('html')[0].setAttribute('dir', language.isRTL ? 'rtl' : 'ltr');
        return update(state, {$merge: {languageID, language, direction: language.direction, isRTL: language.isRTL}});
      } else {
        return state
      }
    }
    default:
      return state
  }
}