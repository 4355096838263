// eslint-disable-next-line
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useRouter} from "./router";
import {defaultOnFormHandle, defaultOnGridHandle} from "./form";

export const DefaultGridPageWrapper = (query?: string) => {
  const router = useRouter();
  const [editItemID, setEditItemID] = useState((query) ? router.query[query] === 'true' ? true : router.query[query] : '');
  const [isEditAfter, setIsEditAfter] = useState(false);
  const grid = useRef<any>(null);
  const form = useRef<any>(null);
  // handlers
  const onGridHandle = useCallback(payload =>
      defaultOnGridHandle({payload, form, setEditItemID, isEditAfter, setIsEditAfter}),
    [form, setEditItemID, isEditAfter, setIsEditAfter]);
  const onFormHandle = useCallback(value =>
      defaultOnFormHandle({value, grid, setIsEditAfter, setEditItemID}),
    [grid, setIsEditAfter]);
  useEffect(() => {
    if (query) router.replaceQuery({[query]: editItemID || null});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItemID]);
  return {grid, form, onGridHandle, onFormHandle, editItemID, setEditItemID};
};
export default DefaultGridPageWrapper;
