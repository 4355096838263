import {iLanguage} from "services/Language";

const API_ENDPOINT = 'Labels'
export const API_LABELS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  CREATE: `${API_ENDPOINT}/Create`,
  PATCH: `${API_ENDPOINT}/Patch/\${data.id}`,
  DELETE: `${API_ENDPOINT}/Delete/\${data.id}`,

  // additions
  GET_DEFAULT_LANGUAGE: `${API_ENDPOINT}/GetDefaultLanguage`,
}

export interface iLabel {
  id?: string;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  isActive?: boolean;
  labelKey?: string;
  title?: string;

  labelSourceID?: string;
  labelSource?: any;

  languageID?: string;
  language?: iLanguage;
}