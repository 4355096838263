import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {apiStatic, apiStaticCanceled} from "AurionCR/components";
import {sagaErrorHandler} from "store";
import {iCaseMedicalCondition, iMedicalConditionGroup} from "./@types";
import axios from "axios";

function* init({payload}: { type: string; payload: { casePageID: string } }): any {
  const {casePageID} = payload;
  const {init} = yield select((state: any) => state.flowEditor.cases[casePageID]);
  if (!init) {
    yield put({type: 'FECaseMedicalConditionGroupLoading', payload: {casePageID, value: true}});
    try {
      let {data: {value}}: { data: { value: iMedicalConditionGroup[] } } = yield call(
        apiStatic.get,
        'CaseMedicalConditionGroups/GetAllDynamic', {
          params: {
            Select: [
              'id',
              'catalogName',
              'pageNumber',
              `caseMedicalConditions.Select(i => new {
              i.id,
              i.casePageID,
              i.medicalConditionID,
              i.medicalCondition.catalogName,
              i.caseMedicalConditionGroupID,
              i.orderNumber,
              i.referOnYes,
              i.referOnNo
            })`.replace(/\s/gm, '') + ' as caseMedicalConditions'
            ].join(),
            Filter: `casePageID == "${casePageID}"`,
            OrderBy: 'pageNumber asc'
          }
        }
      );
      const {
        pages,
        pagesContent
      } = value.reduce((acc: { pages: iMedicalConditionGroup[], pagesContent: iCaseMedicalCondition[][] }, value) => {
        const {caseMedicalConditions, ...page} = value;
        page.open = false;
        acc.pages.push(page);
        acc.pagesContent.push(caseMedicalConditions || []);
        return acc;
      }, {pages: [], pagesContent: []});
      yield put({type: 'FEUpdateCase', payload: {id: casePageID, data: {pages, pagesContent, init: true}}});
    } catch (e) {
      yield call(sagaErrorHandler, e);
    }
    yield put({type: 'FECaseMedicalConditionGroupLoading', payload: {casePageID, value: false}});
  }
}

//TODO: PAGES sagas
function* pageNormalizableOrder({payload}: { payload: { casePageID: string } }): any {
  const {casePageID} = payload;
  let {pages} = yield select((state: any) => state.flowEditor.cases[casePageID]);
  yield all(pages.map((page: any, index: number) => {
    if (page.pageNumber !== index) {
      return call(pageUpdate, {type: '', payload: {casePageID, index, data: {pageNumber: index}}});
    } else {
      return 'ok'
    }
  }))
}

function* pageUpdate({payload}: { type: string; payload: { casePageID: string; index: number; data: any } }): any {
  const {casePageID, index, data} = payload;
  yield put({type: 'FECaseMedicalConditionGroupPageUpdate', payload});
  // get id
  const {pages} = yield select((state: any) => state.flowEditor.cases[casePageID]);
  const {id} = pages[index];
  yield put({type: 'FECaseMedicalConditionGroupRequests', payload: {casePageID, value: true}});
  try {
    yield call(apiStaticCanceled, {
      method: 'patch',
      url: `CaseMedicalConditionGroups/Patch/${id}`,
      data,
      _cancelID: `CaseMedicalConditionGroups_${id}`
    });
  } catch (e) {
    if (!axios.isCancel(e)) yield call(sagaErrorHandler, e);
  }
  yield put({type: 'FECaseMedicalConditionGroupRequests', payload: {casePageID, value: false}});
}

function* pageAdd({payload}: { type: string; payload: { casePageID: string; data: any } }): any {
  const {casePageID, data: pageData} = payload;
  yield put({type: 'FECaseMedicalConditionGroupLoading', payload: {casePageID, value: true}});
  try {
    const {pages} = yield select((state: any) => state.flowEditor.cases[casePageID]);
    const {data} = yield call(
      apiStatic.post,
      'CaseMedicalConditionGroups/Create',
      {...pageData, pageNumber: pages.length}
    );
    yield put({type: 'FECaseMedicalConditionGroupPageAdd', payload: {casePageID, data: {...data, open: false}}});
  } catch (e) {
    yield call(sagaErrorHandler, e);
  }
  yield put({type: 'FECaseMedicalConditionGroupLoading', payload: {casePageID, value: false}});
}

function* pageRemove({payload}: { type: string; payload: { casePageID: string; id: string; index: number } }): any {
  const {casePageID, id} = payload;
  yield put({type: 'FECaseMedicalConditionGroupPageRemove', payload});
  yield call(pageNormalizableOrder, {payload: {casePageID}});
  yield put({type: 'FECaseMedicalConditionGroupRequests', payload: {casePageID, value: true}});
  try {
    yield call(apiStatic.delete, `CaseMedicalConditionGroups/Delete/${id}`);
  } catch (e) {
    yield call(sagaErrorHandler, e);
  }
  yield put({type: 'FECaseMedicalConditionGroupRequests', payload: {casePageID, value: false}});
}

function* pageDraggableEnd({payload}: { type: string; payload: { casePageID: string; save: boolean } }): any {
  const {save, casePageID} = payload;
  yield put({type: 'FECaseMedicalConditionGroupPageDraggablePageID', payload: {casePageID, id: ''}});
  if (save) {
    yield call(pageNormalizableOrder, {payload: {casePageID}});
  }
}


//TODO: MEDICAL CONDITIONS sagas
function* pageItemsNormalizableOrder({payload}: { payload: { casePageID: string } }): any {
  const {casePageID} = payload;
  const {pages, pagesContent} = yield select((state: any) => state.flowEditor.cases[casePageID]);
  yield all(pagesContent.map((page: any, pageIndex: number) => {
    const caseMedicalConditionGroupID = pages[pageIndex]?.id;
    return all(page.map((element: any, orderNumber: number) => {
      if (element.caseMedicalConditionGroupID !== caseMedicalConditionGroupID || element.orderNumber !== orderNumber) {
        const data = {caseMedicalConditionGroupID, orderNumber};
        return call(caseMedicalConditionUpdate, {
          type: '',
          payload: {casePageID, page: pageIndex, index: orderNumber, data, id: element.id}
        });
      } else {
        return 'ok'
      }
    }));
  }))
}

function* caseMedicalConditionAdd({payload}: { type: string; payload: { casePageID: string; pageIndex: number; medicalConditionID: string; catalogName: string; } }): any {
  const {casePageID, pageIndex, medicalConditionID, catalogName} = payload;
  yield put({type: 'FECaseMedicalConditionGroupLoading', payload: {casePageID, value: true}});
  try {
    const {
      pages: {[pageIndex]: page},
      pagesContent: {[pageIndex]: items}
    } = yield select((state: any) => state.flowEditor.cases[casePageID]);
    const {data} = yield call(
      apiStatic.post,
      'CaseMedicalConditions/Create',
      {casePageID, medicalConditionID, caseMedicalConditionGroupID: page.id, orderNumber: items.length}
    );
    yield put({type: 'FECaseMedicalConditionAdd', payload: {casePageID, page: pageIndex, data: {...data, catalogName}}});
  } catch (e) {
    yield call(sagaErrorHandler, e);
  }
  yield put({type: 'FECaseMedicalConditionGroupLoading', payload: {casePageID, value: false}});
}

function* caseMedicalConditionRemove({payload}: { type: string; payload: { casePageID: string; page: number; index: string; id: string; } }): any {
  const {casePageID, id} = payload;
  yield put({type: 'FECaseMedicalConditionGroupLoading', payload: {casePageID, value: true}});
  try {
    yield call(apiStatic.delete, `CaseMedicalConditions/Delete/${id}`);
    yield put({type: 'FECaseMedicalConditionRemove', payload});
    yield call(pageItemsNormalizableOrder, {payload: {casePageID}});
  } catch (e) {
    yield call(sagaErrorHandler, e);
  }
  yield put({type: 'FECaseMedicalConditionGroupLoading', payload: {casePageID, value: false}});
}

function* caseMedicalConditionUpdate({payload}: { type: string; payload: { casePageID: string; id: string; data: any; page?: number; index?: number } }): any {
  const {casePageID, id, data} = payload;
  yield put({type: 'FECaseMedicalConditionUpdate', payload});
  yield put({type: 'FECaseMedicalConditionGroupRequests', payload: {casePageID, value: true}});
  try {
    yield call(apiStaticCanceled, {
      method: 'patch',
      url: `CaseMedicalConditions/Patch/${id}`,
      data,
      _cancelID: `CaseMedicalConditions_${id}`
    });
  } catch (e) {
    if (!axios.isCancel(e)) yield call(sagaErrorHandler, e);
  }
  yield put({type: 'FECaseMedicalConditionGroupRequests', payload: {casePageID, value: false}});
}

function* caseMedicalConditionDraggableEnd({payload}: { type: string; payload: { casePageID: string; save: boolean } }): any {
  const {save, casePageID} = payload;
  yield put({type: 'FECaseMedicalConditionDraggable', payload: {casePageID, id: '', page: -1, type: 1}});
  if (save) {
    yield call(pageItemsNormalizableOrder, {payload: {casePageID}});
  }
}

export const MedicalConditionGroupSagas = [
  takeEvery('FECaseMedicalConditionGroupSagaInit', init),
  // PAGES
  takeEvery('FECaseMedicalConditionGroupSagaPageAdd', pageAdd),
  takeEvery('FECaseMedicalConditionGroupSagaPageRemove', pageRemove),
  takeEvery('FECaseMedicalConditionGroupSagaPageUpdate', pageUpdate),
  takeEvery('FECaseMedicalConditionGroupSagaPageDraggableEnd', pageDraggableEnd),
  // MEDICAL CONDITIONS
  takeEvery('FECaseMedicalConditionSagaAdd', caseMedicalConditionAdd),
  takeEvery('FECaseMedicalConditionSagaRemove', caseMedicalConditionRemove),
  takeEvery('FECaseMedicalConditionSagaUpdate', caseMedicalConditionUpdate),
  takeEvery('FECaseMedicalConditionSagaDraggableEnd', caseMedicalConditionDraggableEnd),
]