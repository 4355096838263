import {IRoute} from "../../components/router";
const ROUTES_INIT = 'ROUTES_INIT';

export default (state: any = {
  routes: [],
  flat: [],
  map: {},
  loginPath: '/login',
  basename: '/'
}, action: any) => {
  switch (action.type) {
    case ROUTES_INIT :
      return {
        ...state,
        ...action.payload
      };
    default:
      return state
  }
}

export const initRoutes = (routes: IRoute[], flat:IRoute[], map: {[key:string]:IRoute}, basename: string, loginPath: string) => ({
  type: ROUTES_INIT,
  payload: {routes, flat, map, basename, loginPath}
});
