import React, {FC} from 'react';
// style
import theme from "styles/theme"
import "AurionCR/styles/_main.scss"
import "styles/_project-theme.scss"
// store
import {Provider} from "react-redux";
import store from "AurionCR/store"
import storeConfig from "store/redux"
// layout
import Page404 from 'pages/Page404'
import Layout from 'pages/Layout'
// router
import routesConfig from 'configs/routes';
// notify
import {SnackbarProvider} from "notistack";
// l18n
import {
  AuthContextProvider,
  LoadingOverlayProvider,
  Notifier,
  Routes,
  ResponsiveProvider,
  RTLThemeProvider
} from "AurionCR/components";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {I18nContextProvider} from "components/i18";
import {AppConfigProvider} from "components/app-config";
import {AxiosInterceptors} from "components/axios-interceptors";


const App: FC = () => {
  window.authCookieName_ = 'apo_auth_token_crm';
  window.authRefreshCookieName_ = 'apo_auth_refresh_crm';
  window.authDateCookieName_ = 'apo_auth_expires_crm';

  return <AppConfigProvider>
    <ResponsiveProvider>
      <Provider store={store(storeConfig)}>
        <AxiosInterceptors/>
        <AuthContextProvider>
          <DndProvider backend={HTML5Backend}>
            <I18nContextProvider>
              <RTLThemeProvider theme={theme}>
                <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                  <Notifier/>
                  <LoadingOverlayProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Routes
                        layout={Layout}
                        routes={routesConfig}
                        page404={Page404}
                      />
                    </MuiPickersUtilsProvider>
                  </LoadingOverlayProvider>
                </SnackbarProvider>
              </RTLThemeProvider>
            </I18nContextProvider>
          </DndProvider>
        </AuthContextProvider>
      </Provider>
    </ResponsiveProvider>
  </AppConfigProvider>
};

export default App;
