import update from "immutability-helper";
import OptionsMODS from "./options";

import { getTypeModel } from "./model";

export const findRedFlag = (id: string, redFlags: any[]) => {
  let redFlag;
  redFlags.some((redFlags_: any[], page: number) => {
    return redFlags_.some((item: any, index: number) => {
      if (item.id === id) {
        redFlag = {data: item, index, page};
        return true;
      }
      return false;
    })
  });
  return redFlag;
};

export default (state: any, action: { type: string, payload?: any }) => {
  const {type, payload} = action;
  const state_ = OptionsMODS(state, action);
  switch (type) {
    case 'FERedFlagNew': {
      const {casePageID, data} = payload;
      return update(state_, {cases: {[casePageID]: {newRedFlag: {$set: data}}}});
    }
    case 'FERedFlagRemove': {
      const {casePageID, page, index} = payload;
      return update(state_, {cases: {[casePageID]: {redFlags: {[page]: {$splice: [[index, 1]]}}}}});
    }
    case 'FERedFlagUpdate': {
      const {id, casePageID, data, page, index} = payload;
      const {redFlags} = state_.cases[casePageID];
      if (page !== undefined && index !== undefined) {
        return update(state_, {cases: {[casePageID]: {redFlags: {[page]: {[index]: {$merge: data}}}}}});
      } else {
        const redFlag: any = findRedFlag(id, redFlags);
        if (redFlag) {
          return update(state_, {cases: {[casePageID]: {redFlags: {[redFlag.page]: {[redFlag.index]: {$merge: data}}}}}});
        } else {
          return state_;
        }
      }
    }
    case 'FERedFlagAdd': {
      const {page, index, casePageID, data} = payload;
      return update(state_, {
        cases: {[casePageID]: {redFlags: {[page]: {$splice: [index, 0, data]}}}}
      });
    }
    case 'FERedFlagDraggable': {
      const {casePageID, id, page, type} = payload;
      return update(state_, {cases: {[casePageID]: {draggableRedFlag: {$set: {id, page, type}}}}});
    }
    case 'FERedFlagMove': {
      const {casePageID, id, index, page} = payload;
      const redFlag: any = findRedFlag(id, state_.cases[casePageID].redFlags);
      if (redFlag) {
        const state__ = update(state_, {
          cases: {[casePageID]: {redFlags: {[redFlag.page]: {$splice: [[redFlag.index, 1]]}}}}
        });
        return update(state__, {
          cases: {
            [casePageID]: {
              redFlags: {[page]: {$splice: [[index, 0, redFlag.data]]}},
              draggableRedFlag: {$set: {id, page, type: redFlag.data.redFlagType}}
            }
          }
        });
      } else {
        const {type} = state_.cases[casePageID].draggableRedFlag;
        return update(state_, {
          cases: {
            [casePageID]: {
              redFlags: {
                [page]: {
                  $splice: [[index, 0, {
                    ...getTypeModel(type || 1),
                    id: 'new-red-flag'
                  }]]
                }
              },
              draggableRedFlag: {$set: {id, page, type}}
            }
          }
        });
      }
    }
    default:
      return state_
  }
};
