import React, { useEffect } from 'react';
import { api, apiStatic } from 'AurionCR/components';
import axios, { AxiosRequestConfig } from 'axios';
import { prepareDateToSave } from './index';

const instances = [apiStatic, api, axios];
const prepareConfig = (conf: AxiosRequestConfig) => {
  if (conf.method === 'post' && conf.data && typeof conf.data === 'object') {
    conf.data = prepareDateToSave(conf.data);
  }
  return conf;
};

export const AxiosInterceptorPost: React.FC = ({ children }) => {
  useEffect(() => {
    instances
      .map((instance) => {
        return instance.interceptors.request.use(prepareConfig);
      });
  }, []);

  return (
    <>
      {children}
    </>
  );
};
