import {lazy} from "react";
import {IRoute} from "AurionCR/components/router";
import DashboardIcon from '@material-ui/icons/Dashboard';
import Person from "@material-ui/icons/Person";
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import AnnouncementIcon from '@material-ui/icons/Announcement';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import AndroidIcon from '@material-ui/icons/Android';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {APP_LOG_ROUTES} from "./constants";

const routes: IRoute[] = [
  {
    path: '/',
    title: 'dashboard',
    icon: DashboardIcon,
    component: lazy(() => import(/* webpackChunkName: 'Dashboard' */ "../pages/Dashboard"))
  },
  {
    path: '/products',
    title: 'products',
    icon: ShoppingBasketIcon,
    permission: 'isAllowToEditFlows',
    routes: [
      {
        path: '/index',
        title: 'products',
        component: lazy(() => import(/* webpackChunkName: 'Products' */ "../pages/Products")),
      },
      {
        path: '/brands',
        title: 'brands',
        component: lazy(() => import(/* webpackChunkName: 'ProductBrands' */ "../pages/ProductBrands")),
      },
    ]
  },
  {
    path: '/tenants',
    title: 'tenants',
    icon: Person,
    permission: 'isAllowToEditTenants',
    routes: [
      {
        path: '/index',
        title: 'tenants',
        component: lazy(() => import(/* webpackChunkName: 'Tenants' */ "../pages/Tenants")),
      },
      {
        path: '/tenant-users',
        title: 'tenant-users',
        component: lazy(() => import(/* webpackChunkName: 'TenantUsers' */ "../pages/TenantUsers")),
      },
      // {
      //   path: '/tenant-families',
      //   title: 'tenant-families',
      //   component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ "../pages/ZTemp")),
      // },
    ]
  },
  {
    path: '/bots',
    title: 'bots',
    icon: AndroidIcon,
    routes: [
      {
        path: '/bots',
        title: 'bots',
        component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ "../pages/ZTemp")),
      },
      {
        path: '/bot-screens',
        title: 'bot-screens',
        component: lazy(() => import(/* webpackChunkName: 'BotScreens' */ "../pages/BotScreens")),
      },
      {
        path: '/bot-sessions',
        title: 'bot-sessions',
        component: lazy(() => import(/* webpackChunkName: 'ZTemp' */ "../pages/ZTemp")),
      },
      {
        path: '/labels-bot',
        title: 'labels-bot',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ "../pages/Labels/bot")),
      },
      {
        path: '/bot-themes',
        title: 'bot-themes',
        component: lazy(() => import(/* webpackChunkName: 'BotThemes' */ "../pages/BotThemes")),
      },
    ]
  },
  {
    path: '/cases',
    title: 'cases',
    icon: VerticalSplitIcon,
    permission: 'isAllowToEditFlows',
    routes: [
      {
        path: '/cases-categories',
        title: 'cases-categories',
        component: lazy(() => import(/* webpackChunkName: 'CasesCategories' */ "../pages/CasesCategories")),
      },
      {
        path: '/cases-contents',
        title: 'cases-contents',
        component: lazy(() => import(/* webpackChunkName: 'CasesContents' */ "../pages/CasesContents")),
      },
      {
        path: '/sensitivities',
        title: 'sensitivities',
        component: lazy(() => import(/* webpackChunkName: 'Sensitivities' */ "../pages/Sensitivities")),
      },
      {
        path: '/medications',
        title: 'active-ingredients',
        component: lazy(() => import(/* webpackChunkName: 'Medications' */ "../pages/Medications")),
      },
      {
        path: '/medical-conditions',
        title: 'medical-conditions',
        component: lazy(() => import(/* webpackChunkName: 'MedicalConditions' */ "../pages/MedicalConditions")),
      },
      {
        path: '/dietary-preferences',
        title: 'dietary-preferences',
        component: lazy(() => import(/* webpackChunkName: 'DietaryPreferences' */ "../pages/DietaryPreferences")),
      },
      {
        path: '/body-parts',
        title: 'body-parts',
        component: lazy(() => import(/* webpackChunkName: 'BodyParts' */ "../pages/BodyParts")),
      },
      {
        path: '/dosage-form-categories',
        title: 'dosage-form-categories',
        component: lazy(() => import(/* webpackChunkName: 'DosageFormCategories' */ "../pages/DosageFormCategories")),
      },
      {
        path: '/dosage-forms',
        title: 'dosage-forms',
        component: lazy(() => import(/* webpackChunkName: 'DosageForms' */ "../pages/DosageForms")),
      },
      {
        path: '/health-care-tips',
        title: 'health-care-tips',
        component: lazy(() => import(/* webpackChunkName: 'HealthCareTips' */ "../pages/HealthCareTips")),
      },
      {
        path: '/DosageFormUnitTypes',
        title: 'dosage-form-unit-types',
        component: lazy(() => import(/* webpackChunkName: 'DosageFormUnitTypes' */ "../pages/DosageFormUnitTypes")),
      }
    ]
  },
  {
    path: '/crm',
    title: 'crm',
    icon: MonetizationOnIcon,
    permission: 'isAllowToViewSensitiveInformation',
    routes: [
      {
        path: '/pharma-companies',
        title: 'pharma-companies',
        component: lazy(() => import(/* webpackChunkName: 'PharmaCompanies' */ "../pages/PharmaCompanies")),
      },
      {
        path: '/pharma-company-orders',
        title: 'pharma-company-orders',
        component: lazy(() => import(/* webpackChunkName: 'PharmaCompanyOrders' */ "../pages/PharmaCompanyOrders")),
      },
    ]
  },
  {
    path: '/content',
    title: 'content',
    icon: AnnouncementIcon,
    permission: 'isAllowToEditWebsites',
    routes: [
      {
        path: '/pages',
        title: 'pages',
        component: lazy(() => import(/* webpackChunkName: 'Pages' */ "../pages/CommonPages")),
      },
      {
        path: '/bot-screens',
        title: 'bot-screens',
        component: lazy(() => import(/* webpackChunkName: 'BotScreens' */ "../pages/BotScreens")),
      },
      {
        path: '/pharma-companies',
        title: 'pharma-companies',
        component: lazy(() => import(/* webpackChunkName: 'PharmaCompanies' */ "../pages/PharmaCompanies")),
      },
      {
        path: '/pharma-company-orders',
        title: 'pharma-company-orders',
        component: lazy(() => import(/* webpackChunkName: 'PharmaCompanyOrders' */ "../pages/PharmaCompanyOrders")),
      },
      {
        path: '/labels-crm',
        title: 'labels-crm',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ "../pages/Labels/crm")),
      },
      {
        path: '/labels-bot',
        title: 'labels-bot',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ "../pages/Labels/bot")),
      },
      {
        path: '/labels-platform',
        title: 'labels-platform',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ "../pages/Labels/platform")),
      },
      {
        path: '/labels-portal',
        title: 'labels-portal',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ "../pages/Labels/portal")),
      },
      {
        path: '/labels-website',
        title: 'labels-website',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ "../pages/Labels/website")),
      },
    ]
  },
  {
    path: '/settings',
    title: 'settings',
    icon: SettingsIcon,
    permission: 'isAllowToEditSetting',
    routes: [
      {
        path: '/users',
        title: 'users-crm',
        permission: 'isAllowToEditPermissions',
        component: lazy(() => import(/* webpackChunkName: 'Users' */ "../pages/Users")),
      },
      {
        path: '/redis-params',
        title: 'redis-params',
        permission: 'isAllowToEditPermissions',
        component: lazy(() => import(/* webpackChunkName: 'RedisParams' */ "../pages/RedisParams")),
      },
      {
        path: '/order-statuses',
        title: 'order-statuses',
        permission: 'isAllowToEditPermissions',
        component: lazy(() => import(/* webpackChunkName: 'OrderStatuses' */ "../pages/OrderStatuses")),
      },
      {
        path: '/countries',
        title: 'countries',
        permission: 'isAllowToEditPermissions',
        component: lazy(() => import(/* webpackChunkName: 'Countries' */ "../pages/Countries")),
      },
      {
        path: '/genders',
        title: 'genders',
        permission: 'isAllowToEditPermissions',
        component: lazy(() => import(/* webpackChunkName: 'Genders' */ "../pages/Genders")),
      },
      {
        path: '/permissions',
        title: 'permissions',
        permission: 'isAllowToEditPermissions',
        component: lazy(() => import(/* webpackChunkName: 'Permissions' */ "../pages/Permissions")),
      },
      {
        path: '/site-parameters',
        title: 'site-parameters',
        component: lazy(() => import(/* webpackChunkName: 'SiteParameters' */ "../pages/SiteParameters")),
      },
      {
        path: '/external-channels',
        title: 'external-channels',
        permission: 'isAllowToEditPermissions',
        component: lazy(() => import(/* webpackChunkName: 'ExternalChannels' */ "../pages/ExternalChannels")),
      },
      {
        path: '/languages',
        title: 'languages',
        component: lazy(() => import(/* webpackChunkName: 'languages' */ "../pages/Languages")),
      },
      {
        path: `/${APP_LOG_ROUTES.CRM_LOGS}`,
        title: 'crm-logs',
        permission: 'isAllowToEditPermissions',
        component: lazy(() => import(/* webpackChunkName: 'Log' */ "../pages/Log")),
      },
      {
        path: `/${APP_LOG_ROUTES.PLATFORM_LOGS}`,
        title: 'platform-logs',
        permission: 'isAllowToEditPermissions',
        component: lazy(() => import(/* webpackChunkName: 'Log' */ "../pages/Log")),
      },
      {
        path: `/${APP_LOG_ROUTES.CMS_LOGS}`,
        title: 'cms-logs',
        permission: 'isAllowToEditPermissions',
        component: lazy(() => import(/* webpackChunkName: 'Log' */ "../pages/Log")),
      },
    ]
  },
  {
    path: '/video',
    title: 'video',
    icon: SettingsIcon,
    inMenu: false,
    private: false,
    inLayout: false,
    routes: [
      {
        path: '/create-room',
        title: 'create-room',
        inMenu: false,
        private: false,
        inLayout: false,
        component: lazy(() => import(/* webpackChunkName: 'VideoRoomCreate' */ "../pages/VideoRoomCreate")),
      },
      {
        path: '/room',
        title: 'room',
        inMenu: false,
        private: false,
        inLayout: false,
        component: lazy(() => import(/* webpackChunkName: 'VideoRoom' */ "../pages/VideoRoom")),
      },
    ]
  },
  {
    path: '/flow-editor',
    title: 'flow-editor',
    icon: ScatterPlotIcon,
    component: lazy(() => import(/* webpackChunkName: 'FlowEditor' */ "../pages/FlowEditor")),
  },
  {
    path: '/profile',
    title: 'profile',
    inMenu: false,
    component: lazy(() => import(/* webpackChunkName: 'Profile' */ "../pages/Profile")),
  },
  {
    path: '/login',
    title: 'login',
    inMenu: false,
    private: false,
    inLayout: false,
    component: lazy(() => import(/* webpackChunkName: 'Login' */ "../pages/Login"))
  },
  {
    path: '/testForm',
    title: 'testForm',
    inMenu: false,
    private: false,
    // inLayout: false,
    component: lazy(() => import(/* webpackChunkName: 'TestForm' */ "../pages/TestForm"))
  }
];

export default routes;
