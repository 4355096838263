import React, {useCallback} from "react";
import {
  Checkbox as MaterialCheckbox,
  CheckboxProps as MaterialCheckboxProps
} from "@material-ui/core";

export interface CheckboxProps extends Omit<MaterialCheckboxProps, 'value' | 'checked' | 'onChange' | 'onBlur'> {
  value?: boolean,
  onChange?: (v: boolean) => void
  onBlur?: () => void
}

export const Checkbox: React.FC<CheckboxProps> = ({ value, onChange, onBlur, ...rest }) => {
  const handlerChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange && onChange(checked)
  }, [onChange])

  return <MaterialCheckbox checked={value} onChange={handlerChange} onBlur={onBlur} {...rest} />
}
