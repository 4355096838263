import {put, call, select, takeLatest, all} from 'redux-saga/effects'
import {apiStatic} from "AurionCR/components";
import {API_LANGUAGES, iLanguage} from "services/Language";
import {iLanguagesStore} from "./module";

function* getDefault() {
  const {languageID}: iLanguagesStore = yield select((state: any) => state.language);
  if (!languageID) {
    const {data: languageID} = yield call(apiStatic.get, API_LANGUAGES.GET_DEFAULT_LANGUAGE)
    yield put({type: 'LANGUAGE_MERGE', payload: {languageID}});
  }
}

function* getLanguages() {
  const {data: {value: languages}} = yield call(apiStatic.get, API_LANGUAGES.GET_ALL_DYNAMIC)
  yield put({
    type: 'LANGUAGE_MERGE',
    payload: {
      ...languages.reduce((acc: any, val: iLanguage) => {
        acc.languages.push(acc.languagesMap[val.id as string] = {...val, direction: val.isRTL ? 2 : 1});
        return acc;
      }, {languages: [], languagesMap: {}})
    }
  });
}

function* init() {
  const {isInit}: iLanguagesStore = yield select((state: any) => state.language);
  if (!isInit) {
    yield all([call(getDefault), call(getLanguages)])
    const {languageID}: iLanguagesStore = yield select((state: any) => state.language);
    yield put({type: 'LANGUAGE_SET_LANG', payload: {languageID}});
    yield put({type: 'LANGUAGE_MERGE', payload: {isInit: true}});
  }
}

export const languageSaga = [
  takeLatest('LANGUAGE_INIT', init),
];
