import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {apiStatic, apiStaticCanceled} from "AurionCR/components";
import {getTypeModel} from "../redFlags/model";
import axios from "axios";
import {sagaErrorHandler} from "store";

function* normalizableOrder({payload}: any) {
  const {casePageID} = payload;
  let {pages} = yield select((state: any) => state.flowEditor.cases[casePageID]);
  yield all(pages.map((page: any, index: number) => {
    if (page.pageNumber !== index) {
      return call(update, {payload: {...payload, index, data: {pageNumber: index}}});
    } else {
      return 'ok'
    }
  }))
}

function* getRedFlags({payload}: any) {
  const {index, redFlags, redFlagTypesMap, pageID} = payload;
  try {
    const {data} = yield call(apiStatic.get, 'RedFlags/GetAllDynamic', {
      params: {
        Select: 'id,redFlagPageID,redFlagTypeID,orderNumber,catalogName,defaultIsYes,defaultIsNo,referOnYes,referOnNo,referFromScore,genderID',
        OrderBy: 'orderNumber asc',
        Filter: `redFlagPageID == "${pageID}"`
      }
    });
    redFlags[index] = data.value.map((item: any) => {
      const type: any = redFlagTypesMap[item.redFlagTypeID];
      return {
        ...getTypeModel(type.rfType || 1),
        ...item,
        redFlagType: type ? type.rfType : '',
        redFlagTypeTitle: type ? type.title : ''
      }
    });
  } catch (e) {
    yield call(sagaErrorHandler, e);
  }
}

function* init({payload}: any) {
  const {casePageID, redFlagTypesMap} = payload;
  const {init} = yield select((state: any) => state.flowEditor.cases[casePageID]);
  if (!init) {
    yield put({type: 'FERedFlagLoading', payload: {casePageID, value: true}});
    try {
      let {data: pages} = yield call(apiStatic.get, 'RedFlagPages/GetAllDynamic', {
        params: {
          Select: 'id,catalogName,pageNumber,minScoreToRefer',
          Filter: `casePageID == "${casePageID}"`,
          OrderBy: 'pageNumber asc'
        }
      });
      pages = pages.value.map((item: any) => ({...item, open: false}));
      const redFlags = pages.map(() => ([]));
      yield all(pages.map((page: any, index: number) => call(getRedFlags, {
        payload: {pageID: page.id, index, redFlags, redFlagTypesMap}
      })));
      yield put({type: 'FEUpdateCase', payload: {id: casePageID, data: {pages, redFlags, init: true}}});
    } catch (e) {
      yield call(sagaErrorHandler, e);
    }
    yield put({type: 'FERedFlagLoading', payload: {casePageID, value: false}});
  }
}

function* add({payload}: any) {
  const {casePageID} = payload;
  yield put({type: 'FERedFlagLoading', payload: {casePageID, value: true}});
  try {
    const {pages} = yield select((state: any) => state.flowEditor.cases[payload.casePageID]);
    const {data} = yield call(apiStatic.post, 'RedFlagPages/Create', {...payload.data, pageNumber: pages.length});
    yield put({type: 'FERedFlagPageAdd', payload: {casePageID, data: {...data, open: false}}});
  } catch (e) {
    yield call(sagaErrorHandler, e);
  }
  yield put({type: 'FERedFlagLoading', payload: {casePageID, value: false}});
}

function* remove({payload}: any) {
  const {casePageID, id} = payload;
  yield put({type: 'FERedFlagPageRemove', payload});
  yield call(normalizableOrder, {payload: {casePageID}});
  yield put({type: 'FERedFlagRequests', payload: {casePageID, value: true}});
  try {
    yield call(apiStatic.delete, `RedFlagPages/Delete/${id}`);
  } catch (e) {
    yield call(sagaErrorHandler, e);
  }
  yield put({type: 'FERedFlagRequests', payload: {casePageID, value: false}});
}

function* update({payload}: any) {
  const {casePageID} = payload;
  yield put({type: 'FERedFlagPageUpdate', payload});
  const {pages} = yield select((state: any) => state.flowEditor.cases[casePageID]);
  const page = pages[payload.index];
  yield put({type: 'FERedFlagRequests', payload: {casePageID, value: true}});
  try {
    yield call(apiStaticCanceled, {
      method: 'patch',
      url: `RedFlagPages/Patch/${page.id}`,
      data: payload.data,
      _cancelID: `RedFlagPages_${page.id}`
    });
  } catch (e) {
    if (!axios.isCancel(e)) yield call(sagaErrorHandler, e);
  }
  yield put({type: 'FERedFlagRequests', payload: {casePageID, value: false}});
}

function* draggableEnd({payload}: any) {
  const {save, casePageID} = payload;
  yield put({type: 'FERedFlagPageDraggablePageID', payload: {casePageID, id: ''}});
  if (save) {
    yield call(normalizableOrder, {payload: {casePageID}});
  }
}

export default [
  takeEvery('FERedFlagInitS', init),
  takeEvery('FERedFlagPageAddS', add),
  takeEvery('FERedFlagPageRemoveS', remove),
  takeEvery('FERedFlagPageNormalizableOrderS', normalizableOrder),
  takeEvery('FERedFlagPageUpdateS', update),
  takeEvery('FERedFlagPageDraggableEndS', draggableEnd),
]
