import {useDispatch} from "react-redux";
import {Ref, useCallback, useEffect, useImperativeHandle, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import axios from "axios";
import {defaultFormGetData} from "./form";
import {useI18n} from "./i18";
import useRouter from "./router";

const EMPTY_PATH = '/not-found'

export const DefaultFormPageWrapper = (
  {
    itemID,
    onFormHandle,
    defaultValues,
    ref,
    apiMethod,
    fieldMethod = 'id',
    customSelect = [],
    titleTemplate = (data) => data.title || '',
    titleNew = 'new',
    onCameEmpty
  }: {
    itemID?: any;
    onFormHandle: (value: any) => void;
    defaultValues: () => any;
    ref: Ref<any>;
    apiMethod: string;
    fieldMethod?: string;
    customSelect?: string[],
    titleTemplate?: (data: any) => string | JSX.Element;
    titleNew?: string;
    onCameEmpty?: () => void
  }
) => {
  const {t} = useI18n();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isNew = useMemo(() => ['true', true].includes(itemID), []);
  const formTitle = useMemo(() => {
    return isNew ? t(titleNew) : Object.keys(data).length ? titleTemplate(data) || '' : 'loading';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemID, isNew, data, t, titleNew, titleTemplate]);
  const {handleSubmit, errors, control, reset, trigger, setValue} = useForm({defaultValues: defaultValues()});
  // handlers
  const onFormSubmit = useCallback((formData: any, e, isEditAfter = false) => {
    setLoading(true);
    onFormHandle({
      type: 'submit',
      isEditAfter,
      payload: {itemID: itemID, row: data, value: formData}
    });
  }, [data, itemID, onFormHandle]);
  const onFormDelete = useCallback(() => {
    onFormHandle({type: 'delete', payload: data});
  }, [data, onFormHandle]);

  const router = useRouter();
  const onEmpty = useCallback(() => {
    if (onCameEmpty) {
      onCameEmpty()
    } else {
      router.replace(EMPTY_PATH)
    }
  }, [onCameEmpty, router]);
  // public
  useImperativeHandle(ref, () => ({setLoading}));
  // init
  useEffect(() => {
    let cancelToken: any;
    if (!isNew) {
      cancelToken = axios.CancelToken.source();
      defaultFormGetData(
        apiMethod,
        {itemID, defaultValues, customSelect, setLoading, setData, reset, dispatch, cancelToken, onCameEmpty: onEmpty},
        fieldMethod
      );
    }
    return () => {
      if (cancelToken) cancelToken.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemID, isNew, setLoading, setData, reset, apiMethod, fieldMethod]);
  // result
  return {
    loading, setLoading,
    data, setData,
    isNew,
    formTitle,
    handleSubmit, errors, control, reset, trigger, setValue,
    onFormSubmit, onFormDelete
  }
};
export default DefaultFormPageWrapper;
