import React from 'react';
import axios from "axios";
import {useI18n} from "../../i18";
import {useDispatch} from "react-redux";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import useRouter from "../../router";
import {getRequest, pathRequest, deleteRequest} from "../../requests";
import {replaceNull} from "../heplers";
import {notifyRequestResult} from "../../../store/modules/notify";
import {requestError, es6Run} from "../../helpers";
import {createRequest} from "../../requests/createRequest";

export enum FORM_HANDLE_TYPES {
  CLOSE_AND_REFRESH = 'closeFormAndGridRefresh',
  SET_ITEM_ID_AND_REFRESH_GRID = 'setEditItemIDAndGridRefresh'
}

export type UseFormHelperFormHandle = (data: { type: FORM_HANDLE_TYPES, payload: { value: string } }) => void
export interface AurionCRUseForm {
  editItemID: string | boolean;
  defaultValues: () => any;
  apiEndpoint: string;
  apiEndpointMainField?: string;
  apiEndpointAdditionsFields?: string[];
  onFormHandle?: UseFormHelperFormHandle
  title?: string | ((data: any) => React.ReactNode);
  titleNew?: string;
}

export const useFormHelper = (
  {
    editItemID,
    defaultValues,
    apiEndpoint,
    apiEndpointMainField = 'id',
    apiEndpointAdditionsFields,
    onFormHandle,
    title = 'title', //TODO: or es6 template `${data.titleEng} - ${datda.titleHeb}`,
    titleNew = 'new',
  }: AurionCRUseForm
) => {
  const {t, labels} = useI18n();
  const dispatch = useDispatch();
  const router = useRouter();
  const [formLoading, setFormLoading] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const formUse = useForm({defaultValues: defaultValues()});
  // calc
  const formIsNew = useMemo(() => ['true', true].includes(editItemID), [editItemID]);
  const formTitle = useMemo(() => {
    const hasData = Object.keys(formData).length
    if (formIsNew) {
      return t(titleNew)
    } else if (hasData && typeof title === 'function') {
      return title(formData)
    } else if (hasData && typeof title === 'string') {
      return es6Run(formData, title)
    } else {
      return t('loading')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formIsNew, formData, labels, titleNew, title, t]);
  // methods
  const formGetData = useCallback((cancelToken) => {
    return getRequest(
      {
        apiEndpoint, cancelToken,
        params: {
          'Select': [...Object.keys(defaultValues()), apiEndpointMainField, ...(apiEndpointAdditionsFields || [])].toString(),
          'Filter': `${apiEndpointMainField}=="${editItemID}"`
        }
      })
      .then(response => {
        if (response.data.value[0]) {
          const data = replaceNull(response.data.value[0]);
          setFormData(data);
          formUse.reset(data);
          setFormLoading(false);
        } else {
          router.replace('/not-found');
        }
      })
      .catch(error => {
        setFormLoading(false);
        dispatch(notifyRequestResult(requestError(error), 'error'));
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formIsNew, apiEndpoint, editItemID, apiEndpointMainField, apiEndpointAdditionsFields]);
  const formSaveData = useCallback((FormData) => {
    return (formIsNew)
      ? createRequest({formData: FormData, apiEndpoint, apiEndpointMainField})
      : pathRequest({formData: FormData, rowData: formData, apiEndpoint, apiEndpointMainField})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formIsNew, formData, formIsNew, apiEndpoint, apiEndpointMainField]);
  const formSubmit = useCallback((FormData) => {
    setFormLoading(true);
    return formSaveData(FormData)
      .then(data => {
        const mainID: string = data[apiEndpointMainField]
        setFormLoading(false);
        if (onFormHandle) onFormHandle({ type: FORM_HANDLE_TYPES.CLOSE_AND_REFRESH, payload: { value: mainID }  });
      })
      .catch(error => {
        setFormLoading(false);
        dispatch(notifyRequestResult(requestError(error), 'error'));
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSaveData, onFormHandle, apiEndpointMainField]);
  const formDelete = useCallback(async () => {
    setFormLoading(true);
    return deleteRequest({ apiEndpoint, formData, apiEndpointMainField })
      .then(({ data }) => {
        if (onFormHandle) onFormHandle({ type: FORM_HANDLE_TYPES.CLOSE_AND_REFRESH, payload: { value: '' } });
        return data
      })
      .finally(() => {
        setFormLoading(false);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiEndpoint, formData, onFormHandle]);
  // init
  useEffect(() => {
    let cancelToken: any;
    if (!formIsNew) {
      formGetData(cancelToken = axios.CancelToken.source());
    } else {
      formUse.reset(defaultValues())
      setFormData({})
    }
    return () => {
      if (cancelToken) cancelToken.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formIsNew, formGetData, formUse.reset, setFormData, defaultValues]);

  // result
  return {
    formLoading, setFormLoading,
    formData, setFormData,
    formTitle,
    formIsNew,
    formUse,
    formGetData, formSaveData,
    formSubmit, formDelete
  }
};
