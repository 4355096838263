import React, {forwardRef} from "react";
import {IconButton} from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

interface SwapProps {
  onTasks: (tasks: any[]) => void;
  pagination: any;
  scroll: any;
  rowData: any;
  index: number;
}

export const Swap = forwardRef<any, SwapProps>((
  {
    onTasks,
    pagination,
    scroll,
    rowData,
    index
  }, ref
) => {
  return (
    <td
      className="data-grid-col-controls swap"
      style={{transform: `translateX(${scroll.x}px)`}}
    >
      <div className={'swap-wrap'}>
        <IconButton ref={ref}>
          <DragIndicatorIcon/>
        </IconButton>
      </div>
    </td>
  )
});

export default Swap;
