import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {apiStatic, apiStaticCanceled} from "AurionCR/components";
import axios from "axios";
import {RedFlagOptionModel} from "../model";
import {sagaErrorHandler} from "store";

function* normalizableOrder({payload}: any) {
  const {casePageID, pageIndex, redFlagIndex} = payload;
  const {options: {data}} = yield select((state: any) => state.flowEditor.cases[casePageID].redFlags[pageIndex][redFlagIndex]);
  yield all(data.map((option: any, index: number) => {
    if (option.orderNumber !== index) {
      return call(update, {payload: {...payload, index, id: option.id, data: {orderNumber: index}}});
    } else {
      return 'ok'
    }
  }))
}

function* add({payload}: any) {
  const {casePageID, pageIndex, redFlagIndex, redFlagID, data} = payload;
  yield put({
    type: 'FERedFlagOptionsUpdate',
    payload: {...payload, data: {loading: true}}
  });
  try {
    const {options: {data: items}} = yield select((state: any) => state.flowEditor.cases[casePageID].redFlags[pageIndex][redFlagIndex]);
    const {data: response} = yield call(apiStatic.post, 'RedFlagOptions/Create', {
      ...data,
      redFlagID,
      orderNumber: items.length
    });
    yield put({type: 'FERedFlagOptionAdd', payload: {...payload, data: response}});
  } catch (e) {
    yield call(sagaErrorHandler, e);
  }
  yield put({
    type: 'FERedFlagOptionsUpdate',
    payload: {...payload, data: {loading: false}}
  });
}

function* remove({payload}: any) {
  const {casePageID, id} = payload;
  yield put({type: 'FERedFlagOptionRemove', payload});
  yield call(normalizableOrder, {payload});
  yield put({type: 'FERedFlagRequests', payload: {casePageID, value: true}});
  try {
    yield call(apiStatic.delete, `RedFlagOptions/Delete/${id}`);
  } catch (e) {
    yield call(sagaErrorHandler, e);
  }
  yield put({type: 'FERedFlagRequests', payload: {casePageID, value: false}});
}

function* update({payload}: any) {
  const {id, data, casePageID} = payload;
  yield put({type: 'FERedFlagOptionUpdate', payload});
  yield put({type: 'FERedFlagRequests', payload: {casePageID, value: true}});
  try {
    yield call(apiStaticCanceled, {
      method: 'patch',
      url: `RedFlagOptions/Patch/${id}`,
      data,
      _cancelID: `RedFlagOption_${id}`
    });
  } catch (e) {
    if (!axios.isCancel(e)) yield call(sagaErrorHandler, e);
  }
  yield put({type: 'FERedFlagRequests', payload: {casePageID, value: false}});
}

function* draggableEnd({payload}: any) {
  const {save} = payload;
  yield put({
    type: 'FERedFlagOptionsUpdate',
    payload: {...payload, data: {draggableID: ''}}
  });
  if (save) {
    yield call(normalizableOrder, {payload});
  }
}

function* open({payload}: any) {
  const {casePageID, pageIndex, redFlagIndex, redFlagID, open} = payload;
  const {options: {init}} = yield select((state: any) => state.flowEditor.cases[casePageID].redFlags[pageIndex][redFlagIndex]);
  yield put({
    type: 'FERedFlagOptionsUpdate',
    payload: {...payload, data: {open, init: true, loading: (open && !init)}}
  });
  if (open && !init) {
    try {
      let {data} = yield call(apiStatic.get, 'RedFlagOptions/GetAllDynamic', {
        params: {
          Select: `id,${Object.keys(RedFlagOptionModel())}`,
          OrderBy: 'orderNumber asc',
          Filter: `redFlagID == "${redFlagID}"`
        }
      });
      yield put({
        type: 'FERedFlagOptionsUpdate',
        payload: {...payload, data: {loading: false, data: data.value}}
      });
    } catch (e) {
      yield call(sagaErrorHandler, e);
    }
  }
}

export default [
  takeEvery('FERedFlagOptionsOpenS', open),
  takeEvery('FERedFlagOptionUpdateS', update),
  takeEvery('FERedFlagOptionAddS', add),
  takeEvery('FERedFlagOptionRemoveS', remove),
  takeEvery('FERedFlagOptionsNormalizableOrderS', normalizableOrder),
  takeEvery('FERedFlagOptionDraggableEndS', draggableEnd),
]
