import React, {FC, useEffect} from "react";
import {closeSnackbar, removeSnackbar} from "../store/modules/notify"
import {useDispatch, useSelector} from "react-redux";
import {IconButton} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {useSnackbar} from "notistack";
import {useI18n} from "./i18";

export const NotifierActionClose: FC<{ notifierKey: string | number | undefined }> = ({notifierKey}) => {
  const dispatch = useDispatch();
  return (
    <IconButton
      aria-label="close"
      color="inherit"
      // className={classes.close}
      onClick={() => dispatch(closeSnackbar(notifierKey))}
    >
      <CloseIcon/>
    </IconButton>
  )
};

let displayed: any = {};
export const Notifier: FC = () => {
  const {t} = useI18n();
  const dispatch = useDispatch();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const notifications = useSelector(state => (state as any).notify.notifications);
  useEffect(() => {
      if (!notifications.length) {
        displayed = {};
      } else {
        notifications.forEach(({key, message, options, dismissed}: any) => {
          if (dismissed) {
            closeSnackbar(key);
          } else if (!displayed[key]) {
            enqueueSnackbar(t(message), {
              ...options,
              // TODO: this example from author library but not work good, onExit be better
              // onClose: (event: React.SyntheticEvent<any>, reason: string, key: number | string) => {
              //   if (options.onClose) options.onClose(event, reason, key);
              // },
              onExit() {
                dispatch(removeSnackbar(key));
              }
            });
            displayed[key] = 1;
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications]
  );
  return null
};
