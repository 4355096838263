import React, { useCallback, useEffect, useState } from 'react';

import {apiStatic, api, Loading, PipelineVariables} from 'AurionCR/components';

export const AppConfigProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const getConfig = useCallback(async () => {
    if (!process.env.REACT_APP_API_SERVER) {
      const result = await fetch('/config.json');
      const config: typeof PipelineVariables = await result.json();
      apiStatic.defaults.baseURL = config.api;
      api.defaults.baseURL = config.api;

      Object.keys(config).forEach(key => {
        PipelineVariables[key as keyof typeof PipelineVariables] = config[key as keyof typeof PipelineVariables];
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getConfig().catch((e) => console.error(e, 'load config'))
  }, [getConfig]);

  if (isLoading) return <Loading active={true} />;
  return <>{children}</>;
};
