import React, {memo, useCallback, useMemo, useRef, useState} from "react";
import {IconButton} from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {checkEs6AndRun, getRandomString} from "../helpers";
import Input from "./input";
import {DialogAlert} from "../dialog-alert";
import {useI18n} from "../i18";
import mime from 'mime-types';
import {fromBlob} from 'file-type/browser'

export const FileUploader = memo((
  {
    // api
    apiPath, // API to path model where used Editor, example - 'SiteParameters/Patch/${data.id}'
    apiSet = 'MediaUploads/UploadToCloud',
    // eslint-disable-next-line
    apiRemove = 'MediaUploads/RemoveFromCloud?filePath=${data}',
    filePath = '',
    // file name
    fileNameFromFieldModel = 'id', // field, get from main model
    // form fields
    value,
    onChange,
    error,
    name,
    label,
    placeholder = 'upload-file',
    disabled = false,
    // additions
    isImage = false,
    addDataToPost = false, // add row data to post request,
    fileAccept = '',
    fileMaxSize = 10
  }: {
    // api
    apiPath: string;
    apiSet?: string;
    apiRemove?: string;
    filePath?: string;
    // file name
    fileNameFromFieldModel?: string;
    // form fields
    name?: string;
    label?: string;
    placeholder?: string;
    value: any;
    onChange: (e: any) => void;
    error?: any;
    disabled?: boolean;
    // additions
    isImage?: boolean,
    addDataToPost?: boolean;
    fileAccept?: string; // can be multiple pdf,doc
    fileMaxSize?: number;
  }) => {
  const {t} = useI18n();
  const ref = useRef<any>(null);
  const [uid] = useState(getRandomString());
  const [alertMessage, setAlertMessage] = useState('');
  const [state, setState] = useState<any>({FileName: '', Remove: null});
  const value_ = useMemo(() => typeof value === 'string' ? value : state.FileName, [value, state.FileName]);
  const accept = useMemo<{ input: string, types: null | string[] }>(() => {
    if (fileAccept) {
      const types_ = fileAccept
        .replace(/\s/g, '')
        .split(',')
        .reduce((result: any, item: any) => {
          const type_ = mime.lookup(item);
          if (type_) result.push(type_);
          return result;
        }, []);
      if (types_.length) return {
        input: types_.join(','),
        types: types_
      }
    }
    return {
      input: 'application/octet-stream',
      types: null
    }
  }, [fileAccept]);
  // handlers
  const onChangeData = useCallback((base64 = '', fileName = '') => {
    let value: any = '';
    const {Remove, FileName} = state;
    const remove = (!FileName && value_) ? {method: 'put', url: checkEs6AndRun(apiRemove, value_)} : Remove;
    if (remove || base64) {
      value = {
        mixin_: {requests: {}},
        type_: 'fileUploader',
        value: fileName,
        base64, apiPath, name, fileNameFromFieldModel, addDataToPost
      };
      if (remove) value.mixin_.requests.put = remove;
      if (base64) value.mixin_.requests.post = {
        method: 'post',
        url: apiSet,
        data: {isImage, filePath, fileStreamString: base64.split(',')[1]}
      };
    }
    setState({FileName: fileName, remove});
    if (!Object.keys(value.mixin_.requests).length) {
      onChange('')
    } else {
      onChange({target: {value}});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setState, apiPath, name, isImage, fileNameFromFieldModel, addDataToPost, onChange]);
  const onShowError = useCallback((error, addition = '') => {
    setAlertMessage(t(error) + addition);
    if (ref) ref.current.value = '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAlertMessage, ref]);
  const onLoadFile = useCallback((file_) => {
    let fileReader = new FileReader();
    fileReader.onload = (e: any) => {
      if (e) {
        onChangeData(e.target.result, file_.name);
        if (ref) ref.current.value = ''
      } else {
        onShowError('file-error-load')
      }
    };
    fileReader.readAsDataURL(file_);
  }, [onChangeData, ref, onShowError]);
  const onChangeFile = useCallback((e: any) => {
    const file_ = e.target.files[0];
    if (file_) {
      // 1. fast check to file type
      if (accept.types && !accept.types.some(type => type === file_.type)) {
        onShowError('file-incorrect-type');
        // 2. check to file size
      } else if (fileMaxSize && file_.size > fileMaxSize * 1000000) {
        onShowError('file-max-size', `< ${fileMaxSize}Mb`);
      } else if (accept.types) {
        // 3. slow test to file type
        fromBlob(file_)
          .then((response: any) => {
            if (accept.types?.some(type => type === response.mime)) {
              onLoadFile(file_);
            } else {
              onShowError('file-incorrect-type');
            }
          })
          .catch(_ => {
            onShowError('file-incorrect-type');
          });
      } else {
        onLoadFile(file_);
      }
    }
  }, [fileMaxSize, accept, onLoadFile, onShowError]);
  // render
  return <div className={`file-uploader ${state.FileName ? 'file' : ''}`}>
    <Input
      value={value_}
      label={label}
      disabled={disabled}
      placeholder={placeholder}
      error={error}
      onClear={onChangeData}
      InputProps={{
        readOnly: true,
        endAdornment: <label htmlFor={`${uid}-file-uploader`}>
          <IconButton
            color="primary"
            component="span"
            size="small"
            disabled={disabled}
          >
            <CloudUploadIcon/>
          </IconButton>
        </label>
      }}
    />
    <input
      ref={ref}
      onChange={onChangeFile}
      id={`${uid}-file-uploader`}
      accept={accept.input}
      type="file"
      tabIndex={-1}
    />
    {/* eslint-disable-next-line react-hooks/exhaustive-deps */}
    {value_ && !state.FileName && <a href={value_} target="_blank" rel="noopener noreferrer" >{value_}</a>}
    {alertMessage && <DialogAlert message={alertMessage} onClose={() => setAlertMessage('')}/>}
  </div>;
});

export default FileUploader;
