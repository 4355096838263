import React, {FC, ReactElement, useMemo, useEffect} from "react";
import {getKey} from "AurionCR/components/source";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {iLanguagesStore} from "store/language";
import {API_LABELS} from "services/Label";
import {I18nContext} from "AurionCR/components";

export const I18nContextProvider: FC<{ children: ReactElement }> = ({children}) => {
  const dispatch = useDispatch();
  const default_ = useMemo(() => ({loading: true, data: [], map: {}}), []);
  const {languageID, isInit} = useSelector((state: any) => state.language as iLanguagesStore);
  const labelsPath = useMemo(() => languageID ? `${API_LABELS.GET_ALL_DYNAMIC}?Filter=languageID=="${languageID}" && labelSource.key=="crm"` : null, [languageID]);
  // labels
  const labelsKey = useMemo(() => getKey(API_LABELS.GET_ALL_DYNAMIC), []);
  const labels = useSelector((state: any) => {
    if (labelsPath) {
      const state_ = state.sources[labelsKey];
      return state_ ? state_[labelsPath] || default_ : default_;
    } else {
      return default_
    }
  }, shallowEqual);
  useEffect(() => {
    if (labelsPath) {
      dispatch({type: 'SOURCE_INIT', key: labelsKey, path: labelsPath, fieldID: 'labelKey', mapping: true});
    }
    return () => {
      if (labelsPath) dispatch({type: 'SOURCE_MOUNT_UNMOUNT', key: labelsKey, path: labelsPath, methodType: -1});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelsPath]);
  // init
  useEffect(() => {
    dispatch({type: 'LANGUAGE_INIT'})
  }, [])
  // render
  return (
    <I18nContext.Provider value={{...labels}}>
      {isInit ? children : null}
    </I18nContext.Provider>
  );
};
