const SOURCE_ADD_ROOT = 'SOURCE_ADD_ROOT';
const SOURCE_ADD_CHILDREN = 'SOURCE_ADD_CHILDREN';
const SOURCE_UPDATE_CHILDREN = 'SOURCE_UPDATE_CHILDREN';

export default (state: any = {}, action: any) => {
  let {key, path, props, children} = action.payload || {};
  switch (action.type) {
    case SOURCE_ADD_ROOT :
      return {
        ...state,
        ...action.payload
      };
    case SOURCE_ADD_CHILDREN :
      return {
        ...state,
        [key]: {
          ...state[key],
          ...children
        }
      };
    case SOURCE_UPDATE_CHILDREN :
      return {
        ...state,
        [key]: {
          ...state[key],
          [path]: {
            ...state[key][path],
            ...props
          }
        }
      };
    default:
      return state
  }
};

export const addRoot = (key: string) => ({
  type: SOURCE_ADD_ROOT,
  payload: {[key]: {}}
});
export const addChildren = (key: string, path: string, fieldID: string, mapping:boolean) => ({
  type: SOURCE_ADD_CHILDREN,
  payload: {
    key,
    children: {
      [path]: {
        loading: false,
        data: [],
        map: {},
        latest: 0,
        update: true,
        watchers: 1,
        mapping,
        fieldID
      }
    }
  }
});
export const updateChildren = (key: string, path: string, props: any) => ({
  type: SOURCE_UPDATE_CHILDREN,
  payload: {key, path, props}
});
