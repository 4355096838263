import {useCallback, useState} from "react";

export interface useFormHelperDeleteProps<T = any> {
  deleteMiddleware: () => Promise<T>
}
export const useDeleteDialog = <T = any>({ deleteMiddleware }: useFormHelperDeleteProps<T>) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const handleConfirm = useCallback(async () => {
    await deleteMiddleware()
    setIsOpenDelete(true)
  }, [deleteMiddleware])
  const handleClose = useCallback(() => {
    setIsOpenDelete(false)
  }, [])
  const handleOpen = useCallback(() => {
    setIsOpenDelete(true)
  }, [])

  return {
    isOpenDelete,
    handleConfirm,
    handleClose,
    handleOpen
  }
}
