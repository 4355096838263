import React, {memo, useCallback, useState} from "react";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LinkIcon from "@material-ui/icons/Link";
import {IconButton} from "@material-ui/core";
import Input from "./input";
import CopyPathToClipboard from "../copy-path-to-clipboard";

export const Password = memo((props: any) => {
  const [type, setType] = useState<string>('password');
  const onToggleType = useCallback(() => {
    setType(state => state === 'password' ? 'text' : 'password');
  }, [setType]);
  const onMouseDown = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  return <Input
    {...props}
    type={type}
    InputProps={{
      endAdornment: <>
        <IconButton
          size="small"
          // aria-label="toggle password visibility"
          onClick={onToggleType}
          onMouseDown={onMouseDown}
        >
          {type === 'password' ? <Visibility/> : <VisibilityOff/>}
        </IconButton>
        <CopyPathToClipboard text={props.value} message="copy-to-clipboard ">
          <IconButton size="small">
            <LinkIcon/>
          </IconButton>
        </CopyPathToClipboard>
      </>
    }}
  />;
});
export default Password;
