import React, {memo, useMemo} from "react"
import {CssBaseline, ThemeProvider, StylesProvider, jssPreset} from '@material-ui/core';
import {useSelector} from "react-redux";
import rtl from 'jss-rtl';
import {create} from 'jss';

export const RTLThemeProvider = memo(({theme, children}: any) => {
  const {direction} = useSelector(state => (state as any).language);
  const theme_ = useMemo(() => {
    return {...{}, ...theme, ...{direction: direction === 2 ? 'rtl' : 'ltr'}};
  }, [direction, theme]);
  const jss = useMemo(() => {
    return (direction === 2) ? create({plugins: [...jssPreset().plugins, rtl()]}) : null;
  }, [direction]);
  const child = useMemo(() => {
    return <>
      <CssBaseline/>
      {children}
    </>;
  }, [children]);
  return <>
    <ThemeProvider theme={theme_}>
      {jss
        ? <StylesProvider jss={jss}>{child}</StylesProvider>
        : child
      }
    </ThemeProvider>
  </>;
});
export default RTLThemeProvider;