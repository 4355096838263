import { api, apiStatic, getCookies } from 'AurionCR/components';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

let isRefreshing = false;
let refreshingCall: Promise<any>;

interface Props {
  onRefresh: () => Promise<{ token: string; refreshToken: string; expires: string }>;
  onSuccess: (p: { token: string; refreshToken: string; expires: string }) => void;
  onError: (e: Error) => void;
}

const instances = [apiStatic, api, axios];
export const AxiosInterceptorsRefreshToken: React.FC<Props> = ({
  children,
  onRefresh,
  onSuccess,
  onError,
}) => {
  const [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true);
    const IDS = instances.map((instance) => {
      return instance.interceptors.response.use(
        (response) => response,
        (error) => {
          const status = error.response ? error.response.status : null;

          const Token = getCookies(window.authCookieName_);
          const RefreshToken = getCookies(window.authRefreshCookieName_);

          if (status !== 401 || !(Token && RefreshToken)) {
            return Promise.reject(error);
          }

          if (isRefreshing) {
            return refreshingCall.then(() => {
              return instance(error.config);
            });
          }
          isRefreshing = true;
          refreshingCall = onRefresh()
            .then((res) => {
              isRefreshing = false;
              const { token, refreshToken, expires } = res;
              onSuccess({ token, refreshToken, expires });
              return instance(error.config);
            })
            .catch((err) => {
              const errorStatus = err.response ? err.response.status : null;
              isRefreshing = false;
              if (errorStatus === 401) {
                onError(err);
              }
              return Promise.reject(err);
            });
          return refreshingCall;
        },
      );
    });

    return () => {
      instances.forEach((instance, i) => {
        instance.interceptors.response.eject(IDS[i]);
      });
    };
  }, [onRefresh, onSuccess, onError]);

  return <>{canRender ? children : null}</>;
};
