import React, {memo, useMemo} from "react";
import source from "AurionCR/components/source";

export default memo(() => {
  const source_ = source('ExternalChannels/GetAllDynamic?Select=id,title,description,linkURL,icon&Filter=isActive==true');
  const data = useMemo(() => {
    const data_ = source_.data.filter((item: any) => item.icon).map((item: any) => ({
      ...item,
      title_: `${item.title || ''}${item.title && item.description ? ': ' : ''}${item.description || ''}`
    }));
    if (data_.length) return data_;
    return null;
  }, [source_.data]);
  return <>
    {data &&
    <div className="root-icons-nav">
      <ul>
        {data.map((item: any) =>
          <li key={item.id}>
            <a
              href={item.linkURL}
              title={item.title_}
              target="_blank"
            >
              <img src={item.icon} alt=""/>
            </a>
          </li>
        )}
      </ul>
    </div>
    }
  </>
})
