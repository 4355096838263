import {createStore, compose, applyMiddleware, combineReducers} from "redux"
import createSagaMiddleware from "redux-saga"
import {all} from "redux-saga/effects";

const store = (config: any) => {
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];
  const store = createStore(combineReducers(config.store), composeEnhancers(applyMiddleware(...middleware)));

  function* rootSaga() {
    yield all(config.sagas);
  }

  sagaMiddleware.run(rootSaga);
  return store;
};

export default store
