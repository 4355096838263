import React, {FC, memo, useCallback} from "react";
import {Button} from "@material-ui/core";
import {useI18n} from "../../../../i18";

const Footer: FC<{ onClose: () => void; }> = ({onClose}) => {
  const {t} = useI18n();
  const onCancel = useCallback((e) => {
    e.stopPropagation();
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="grid-editor-footer">
      <Button
        color="secondary"
        type="submit"
      >
        {t('save')}
      </Button>
      <Button
        color="secondary"
        onClick={onCancel}
      >
        {t('cancel')}
      </Button>
    </div>
  );
};

export default memo(Footer);
