import {api} from "../helpers";
import {parseFormData, saveMixinsFormData} from "./helpers";

export interface AurionCRCreateRequest {
  formData: any;
  apiEndpoint: string;
  apiEndpointMainField?: string;
}

export const createRequest = async (
  {
    formData,
    apiEndpoint,
    apiEndpointMainField = 'id'
  }: AurionCRCreateRequest
) => {
  const {data, mixins} = parseFormData(formData);
  const response = await api({method: 'post', url: `${apiEndpoint}/Create`, data});
  const resultData = typeof response.data === 'object' ? response.data : {[apiEndpointMainField]: response.data};
  if (mixins.length) await saveMixinsFormData(mixins, resultData);
  return resultData;
};
