import {merge, uniq} from "lodash"
import * as models from "./model";
import {checkES6Template, regExpFieldAS} from "../helpers";

export const parser = ({config, auth}: { config: models.IDataGrid, auth: any }) => {
  let {id, store, pagination, columns, toolbar, controls} = merge({}, models.model(), config);
  let filters: any = {};
  let selected_: any[] = [store.mainField];
  //swap
  if (store.swap && store.swap.field) selected_.push(store.swap.field);
  //columns
  columns = columns
    .filter(column => auth.permission(column.permission))
    .map(value => {
      let result = merge(models.column(), value);
      // - check field
      if (result.field) {
        selected_.push(result.field);
        if (regExpFieldAS.test(result.field)) {
          // @ts-ignore
          result.field = result.field.match(regExpFieldAS)[0].slice(4)
        }
      }
      // - template
      if (typeof result.template === 'object') {
        switch (result.template.type) {
          case "date":
            result.template = {...models.columnTemplateDate(), ...result.template};
            break;
          case "image":
            result.template = {...models.columnTemplateImage(), ...result.template};
            ['src', 'title'].forEach(key => {
              // @ts-ignore
              const val_ = result.template[key];
              if (val_ && !checkES6Template(val_)) selected_.push(val_);
            });
            break;
          default: {
            console.error('Error parse column template', value, config);
            result.template = 'string';
          }
        }
      }
      // - editor
      if (result.editor) {
        // @ts-ignore
        if (['string', 'number', 'boolean'].includes(result.editor)) {
          // @ts-ignore
          result.editor = {...models.getColumnEditor(result.editor)};
          // @ts-ignore
        } else if (['string', 'number', 'boolean', 'select', 'custom'].includes(String(result.editor.type))) {
          // @ts-ignore
          result.editor = auth.permission(result.editor.permission) ? {...models.getColumnEditor(String(result.editor.type)), ...result.editor} : null;
        } else {
          console.error('Error parse column editor', value, config);
          result.editor = null;
        }
        // @ts-ignore
        if (result.editor) {
          // @ts-ignore
          if (!result.editor?.field) result.editor.field = result.field;
          // @ts-ignore
          selected_.push(result.editor?.field);
        }
      }
      return result;
    });
  // store
  if (store.get.selected) selected_ = [...selected_, ...store.get.selected.split(',')];
  store.get.selected = uniq(selected_).join(',');
  // filters
  if (toolbar.filters) {
    toolbar.filtersMap = {};
    toolbar.filters = toolbar.filters
      .filter(value => auth.permission(value.permission))
      .map((value: models.IDataGridFilter) => {
        let result: any = {};
        // @ts-ignore
        if (typeof value.options === 'string') {
          result = merge(result, models.filter(value.options), {field: value.field});
        } else {
          // @ts-ignore
          result = merge(result, models.filter(value.options?.type), value)
        }
        if (result.options?.value !== undefined) filters[result.field] = result.options.value;
        toolbar.filtersMap[result.field] = result;
        return result;
      })
  }
  // toolbar
  ['new', 'export'].forEach(key => {
    // @ts-ignore
    if (toolbar.controls[key] && !auth.permission(toolbar.controls[key].permission)) toolbar.controls[key] = false;
  });
  // controls
  if (controls && controls.length) {
    // @ts-ignore
    controls = controls.map((control: any) => {
      let result;
      if (typeof control === 'string') {
        switch (control) {
          case 'edit':
            return merge({}, models.control(), {type: 'edit', title: 'edit'});
          case 'delete':
            return merge({}, models.control(), {type: 'delete', title: 'delete'});
          default:
            return null;
        }
      } else {
        result = merge({}, models.control(), control)
      }
      return result;
    }).filter((control: any) => control && auth.permission(control.permission))
  }
  return {model: {id, store, pagination, columns, toolbar, controls}, filters: filters}
};
