import {all, call, put, select, takeEvery} from "redux-saga/effects";
import update from "immutability-helper";
import axios from "axios";
import {apiStatic, apiStaticCanceled} from "AurionCR/components";
import OptionsSaga from "./options/saga";
import {findRedFlag} from ".";
import {sagaErrorHandler} from "store";

function* normalizableOrder({payload}: any) {
  const {casePageID} = payload;
  const {pages, redFlags} = yield select((state: any) => state.flowEditor.cases[casePageID]);
  yield all(redFlags.map((page: any, pageIndex: number) => {
    const redFlagPageID = pages[pageIndex]?.id;
    return all(page.map((redFlag: any, orderNumber: number) => {
      if (redFlag.redFlagPageID !== redFlagPageID || redFlag.orderNumber !== orderNumber) {
        const data = {redFlagPageID, orderNumber};
        return call(Update, {payload: {casePageID, page: pageIndex, index: orderNumber, data, id: redFlag.id}});
      } else {
        return 'ok'
      }
    }));
  }))
}

function* add({payload}: any) {
  const {casePageID, data, redFlagTypes, redFlagTypesMap} = payload;
  const {pages, newRedFlag: {data: redFlagData, page, index}} = yield select((state: any) => state.flowEditor.cases[casePageID]);
  yield put({
    type: 'FEUpdateCase',
    payload: {id: casePageID, data: {newRedFlag: null, draggableRedFlag: {id: '', page: -1, type: 1}}}
  });
  const redFlag = update({
    ...redFlagData,
    ...data,
    redFlagPageID: pages[page].id,
    orderNumber: index,
    redFlagTypeID: redFlagTypes.filter((item: any) => item.rfType === redFlagData.redFlagType)[0]?.id || ''
  }, {$unset: ['redFlagTypeTitle', 'redFlagType', 'open', 'id']});
  yield put({type: 'FERedFlagLoading', payload: {casePageID, value: true}});
  try {
    const {data} = yield call(apiStatic.post, 'RedFlags/Create', redFlag);
    const type: any = redFlagTypesMap[data.redFlagTypeID];
    yield put({
      type: 'FERedFlagUpdate', payload: {
        casePageID, page, index, data: {
          ...data,
          open: true,
          redFlagType: type ? type.rfType : '',
          redFlagTypeTitle: type ? type.title : ''
        }
      }
    });
    yield call(normalizableOrder, {payload: {casePageID}});
  } catch (e) {
    yield call(sagaErrorHandler, e);
    yield put({type: 'FERedFlagRemove', payload: {casePageID, page, index}});
  }
  yield put({type: 'FERedFlagLoading', payload: {casePageID, value: false}});
}

function* remove({payload}: any) {
  const {casePageID, id} = payload;
  yield put({type: 'FERedFlagRemove', payload});
  yield call(normalizableOrder, {payload: {casePageID}});
  yield put({type: 'FERedFlagRequests', payload: {casePageID, value: true}});
  try {
    yield call(apiStatic.delete, `RedFlags/Delete/${id}`);
  } catch (e) {
    yield call(sagaErrorHandler, e);
  }
  yield put({type: 'FERedFlagRequests', payload: {casePageID, value: false}});
}

function* Update({payload}: any) {
  const {casePageID, id, data} = payload;
  yield put({type: 'FERedFlagUpdate', payload});
  yield put({type: 'FERedFlagRequests', payload: {casePageID, value: true}});
  try {
    yield call(apiStaticCanceled, {
      method: 'patch',
      url: `RedFlags/Patch/${id}`,
      data,
      _cancelID: `RedFlag_${id}`
    });
  } catch (e) {
    if (!axios.isCancel(e)) yield call(sagaErrorHandler, e);
  }
  yield put({type: 'FERedFlagRequests', payload: {casePageID, value: false}});
}

function* draggableEnd({payload}: any) {
  const {save, casePageID} = payload;
  yield put({type: 'FERedFlagDraggable', payload: {casePageID, id: '', page: -1, type: 1}});
  if (save) {
    yield call(normalizableOrder, {payload: {casePageID}});
  }
}

function* newRedFlag({payload}: any) {
  const {create, casePageID} = payload;
  const {redFlags} = yield select((state: any) => state.flowEditor.cases[casePageID]);
  const redFlag: any = findRedFlag('new-red-flag', redFlags);
  if (create) {
    yield put({type: 'FERedFlagNew', payload: {casePageID, data: redFlag}});
  } else {
    if (redFlag) {
      yield put({
        type: 'FERedFlagRemove',
        payload: {casePageID, page: redFlag.page, index: redFlag.index}
      });
    }
    yield put({
      type: 'FEUpdateCase',
      payload: {id: casePageID, data: {newRedFlag: null, draggableRedFlag: {id: '', page: -1, type: 1}}}
    });
  }
}

export default [
  ...OptionsSaga,
  takeEvery('FERedFlagAddS', add),
  takeEvery('FERedFlagRemoveS', remove),
  takeEvery('FERedFlagNormalizableOrderS', normalizableOrder),
  takeEvery('FERedFlagUpdateS', Update),
  takeEvery('FERedFlagNewS', newRedFlag),
  takeEvery('FERedFlagDraggableEndS', draggableEnd),
]
