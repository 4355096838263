import {api} from "../helpers";

export interface AurionCRDeleteRequest {
  apiEndpoint: string;
  formData: any,
  apiEndpointMainField?: string
}

export const deleteRequest = ({apiEndpoint, formData, apiEndpointMainField = 'id'}: AurionCRDeleteRequest) => {
  const id = formData[apiEndpointMainField]
  return new Promise((resolve: (response: any) => void, reject: (error: any) => void) => {
    if (!id) {
      return reject(new Error(`Client error`))
    }
    api(
      {
        method: 'delete',
        url: `${apiEndpoint}/Delete/${id}`
      })
      .then(resolve)
      .catch(reject)
  })
}