import {iLabel} from "services/Label";

const API_ENDPOINT = 'Languages'
export const API_LANGUAGES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  CREATE: `${API_ENDPOINT}/Create`,
  PATCH: `${API_ENDPOINT}/Patch/\${data.id}`,
  DELETE: `${API_ENDPOINT}/Delete/\${data.id}`,

  // additions
  GET_DEFAULT_LANGUAGE: `${API_ENDPOINT}/GetDefaultLanguage`,
}

export interface iLanguage {
  id?: string;
  createdDate?: string | Date;
  updatedDate?: string | Date;
  isActive?: boolean;

  title?: string;
  direction?: 1 | 2;
  genderID?: string;
  culture?: string;
  icon?: string;
  currency?: string;
  importName?: string;
  isoCode?: string;
  rank?: number;
  isMajor?: boolean;
  isRTL?: boolean;
  labels?: iLabel[];
  commonPages?: any[];
  userProfileFamilies?: any[];
  userProfileCRMs?: any[];
  siteParameters?: any[];
  botUserProfiles?: any[];
  botWelcomeMessages?: any[];
  botSessions?: any[];
}