export enum CONTENTS_ID {
  HOME = 1,
  CONTACT = 2,
  CONTACT_THANKS = 3,
  DISCLAIMER = 4,
  ACCESSIBILITY = 5
}
export enum LABELS_KEY {
  BOT = 'bot',
  CRM = 'crm',
  PORTAL = 'portal',
  PLATFORM = 'platform',
  WEBSITE = 'website'
}
export enum CASE_PAGE_TYPE {
  RED_FLAG = 1,
  DOSAGE_FORM = 2,
  SYMPTOM = 3,
  SENSITIVITY = 4,
  MESSAGE = 5,
  MEDICAL_CONDITIONS = 6,
  DECISIONS = 7

}
export enum RED_FLAG_TYPE {
  YES_NO = 1,
  PAIN_SCALE = 2,
  SURVEY = 3
}


export enum APP_LOG_ROUTES {
  CRM_LOGS = 'crm-logs',
  PLATFORM_LOGS = 'platform-logs',
  CMS_LOGS = 'cms-logs',
}

export const APP_LOG_TYPES = {
  [APP_LOG_ROUTES.CRM_LOGS]: '064ec9fb-8e5e-4469-8330-135d6323fb39',
  [APP_LOG_ROUTES.PLATFORM_LOGS]: '8c36ab01-84f0-46e7-86e8-48c342d25995',
  [APP_LOG_ROUTES.CMS_LOGS]: '82296534-2832-41ed-803d-422fdc9cb092',
}
