import update from "immutability-helper";
import {addCaseRedFlag, RedFlagsModifications} from "./redFlag";
import {addCaseMedicalConditionGroup, MedicalConditionGroupState} from "./MedicalConditionGroup";

const INITIAL_STATE = (): any => ({
  cases: {},
  confirmDialog: null,
  case: {
    id: '',
    catalogName: '',
    caseCategoryID: '',
    useForVitAssist: false,
    useForPharmAssist: false,
  }
});

export default (state = INITIAL_STATE(), action: { type: string, payload?: any }) => {
  const {type, payload} = action;
  // modifications
  let state_ = state;
  state_ = RedFlagsModifications(state_, action);
  state_ = MedicalConditionGroupState(state_, action);
  // main modifications
  switch (type) {
    case 'FEReset':
      return INITIAL_STATE();
    case 'FEMergeCase':
      return update(state_, {case: {$merge: payload}});
    case 'FEAddCase':
      return update(state_, {cases: {$merge: payload}});
    case 'FEUpdateCase':
      return update(state_, {cases: {[payload.id]: {$merge: payload.data}}});
    case 'FEDeleteCase':
      return update(state_, {cases: {$unset: [payload]}});
    case 'FEConfirmDialog':
      return update(state_, {confirmDialog: {$set: payload}});
    default:
      return state_;
  }
};
// public
export const reset = () => ({type: 'FEReset'});
export const updateCase = (id: string, data: any) => ({type: 'FEUpdateCase', payload: {id, data}});
export const deleteCase = (id: string) => ({type: 'FEDeleteCase', payload: id});
export const addCase = (data: any, payload?: any) => {
  switch (data.cpType) {
    case 1:
      return {type: 'FEAddCase', payload: addCaseRedFlag(data, payload)};
    case 12:
      return {type: 'FEAddCase', payload: addCaseMedicalConditionGroup(data, payload)};
    default:
      return {type: 'FEAddCase', payload: {[data.id]: data}};
  }
};
