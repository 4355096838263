import React, {memo, useEffect, useMemo, useState} from "react";
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {Button, Dialog, DialogContent, DialogTitle, IconButton, InputBase} from "@material-ui/core";
import {useI18n} from "../i18";
import {useDispatch} from "react-redux";
import {apiStatic, requestError} from "../helpers";
import {notifyRequestResult} from "../../store/modules/notify";
import {Source} from "../source";
import {Loading} from "../loading";
import CloseIcon from "@material-ui/icons/Close";

export const SMDialog = memo((
  {
    data,
    option,
    item,
    value,
    label,
    placeholder,
    onChange,
    onCancel
  }: any) => {
  const [search, setSearch] = useState('');
  const source = useMemo(() => {
    if (data.length) {
      const search_ = search.replace(/[^\w\s]/gi, '\\$&');
      return data.filter((item: any) => (!search_.length || new RegExp(search_, 'gi').test(item[option.label])));
    } else {
      return [];
    }
  }, [data, option, search]);
  const onClickSearch = () => {
    if (!search.length && item) setSearch(item[option.label]);
  };
  return <Dialog
    className="select-mobile-dialog"
    fullScreen
    open={true}
  >
    <DialogTitle>
      {label || placeholder}
      <IconButton
        className="btn-dialog-close"
        onClick={onCancel}
      >
        <CloseIcon/>
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <div className="search">
        <InputBase
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
          placeholder={item ? item[option.label] : placeholder}
          onClick={onClickSearch}
        />
        {search.length > 0 && <IconButton
          size="small"
          onClick={() => setSearch('')}
        >
          <ClearIcon/>
        </IconButton>}
      </div>
      {source.length > 0 && <div className="items">
        <ul className="item">
          {source.map((item: any) => <li
            key={item[option.value]}
            className={item[option.value] === value ? 'selected' : ''}
            onClick={() => onChange(item)}
          >
            {item[option.label]}
          </li>)}
        </ul>
      </div>}
    </DialogContent>
  </Dialog>
});
export const SMContent = memo((
  {
    loading,
    data,
    value,
    option,
    placeholder,
    className,
    onChange,
    allowEmpty,
    clearable,
    disabled,
    ...props
  }: any) => {
  const {t} = useI18n();
  const [isOpen, setIsOpen] = useState(false);
  const defOption = useMemo(() => {
    return {[option.value]: '', [option.label]: allowEmpty ? placeholder || t('empty') : ''};
  }, [placeholder, option, t, allowEmpty]);
  const data_ = useMemo(() => {
    if (allowEmpty) {
      return [defOption, ...data]
    } else {
      return [...data];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, defOption]);
  const item = useMemo(() => {
    if (value && data.length) {
      return data.filter((item: any) => item[option.value] === value)[0];
    } else {
      return null;
    }
  }, [data, value, option]);
  return <>
    <div
      className={`text-field-wrapper select-mobile-input${className}${value && clearable ? ' clear-available' : ''}`}
      onClick={() => setIsOpen(true)}
    >
      <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
        {props.label && <label
          className={`MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink${item ? ' MuiFormLabel-filled' : ''}`}>
          {props.label}
        </label>}
        <div
          className={`MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedEnd${disabled || loading ? ' Mui-disabled' : ''}`}>
          <div className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedEnd">
            {item ? item[option.label] : placeholder}
          </div>
          {value && clearable && <Button
            className="btn-clear"
            onClick={(e: any) => {
              e.stopPropagation();
              onChange({target: {value: ''}}, '');
            }}
          >
            <ClearIcon/>
          </Button>}
          <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd">
            <ArrowDropDownIcon/>
          </div>
        </div>
      </div>
      <Loading active={loading} size={25}/>
    </div>
    {isOpen && <SMDialog
      {...props}
      item={item}
      data={data_}
      option={option}
      value={value}
      placeholder={placeholder}
      onChange={(item: any) => {
        onChange({target: {value: item[option.value]}}, item);
        setIsOpen(false);
      }}
      onCancel={() => setIsOpen(false)}
    />}
  </>;
});
export const SMSourceDynamic = memo(({source, ...rest}: any) => {
  const {data, loading} = Source(source);
  return <SMContent {...rest} loading={loading} data={data}/>;
});
export const SMSourceStatic = memo(({source, ...rest}: any) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!rest.disabled) {
      if (source.url) {
        setLoading(true);
        apiStatic
          .get(source.url)
          .then(response => {
            setData(response.data.value);
            setLoading(false)
          })
          .catch(error => {
            dispatch(notifyRequestResult(requestError(error), 'error'));
            console.error(error);
            setData([]);
            setLoading(false)
          })
      } else {
        console.error('please set correct settings Select', source)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData, setLoading, source]);
  return <SMContent {...rest} loading={loading} data={data}/>;
});
export const SMSourceWrapper = memo(({source, ...rest}: any) => {
  if (source !== undefined) {
    if (Array.isArray(source)) {
      return <SMContent {...rest} loading={false} data={source}/>
    } else if (typeof source === 'string') {
      return <SMSourceDynamic {...rest} source={source}/>
    } else if (typeof source === 'object' && source.url && source.static) {
      return <SMSourceStatic {...rest} source={source}/>
    } else {
      return null;
    }
  } else {
    return <div>Error source settings</div>;
  }
});
export const SelectMobile = memo((
  {
    source,
    value,
    onChange,
    option = {value: 'id', label: 'title'},
    label = '',
    placeholder = 'choose',
    clearable = true,
    className = '',
    style = {},
    error = null,
    disabled = false,
    allowEmpty = false
  }: {
    source: null | string | any[] | {
      url: string;
      // filter: string; // es6 template
      //                 // example:
      //                 //    'someFiled == ${data} || someFiled.contains("${data}")
      //                 //    where, ${data} === selectValue
      // take: number;
      // select?: string;
      static?: boolean;
    };
    value: null | string | number | any[],
    onChange: (e: any, value: any) => void;
    option?: { label?: string; value?: string; };
    label?: string;
    placeholder?: string;
    clearable?: boolean;
    className?: string;
    style?: {};
    error?: null | undefined | {};
    disabled?: boolean;
    allowEmpty?: boolean;
  }) => {
  return <SMSourceWrapper
    source={source}
    value={value}
    onChange={onChange}
    option={option}
    label={label}
    placeholder={placeholder}
    clearable={clearable}
    className={className}
    style={style}
    error={error}
    disabled={disabled}
    allowEmpty={allowEmpty}
  />;
});
