import React, {memo, useCallback, useMemo} from "react";
import {IDataGridFilter} from "../../model";
import {DateMultiple} from '../../../form/date'
import {convertDateToRDateTime, convertRDateTimeToDate} from "../../../helpers"
import {useI18n} from "../../../i18";

export default memo((
  {
    filter,
    value,
    onTasks
  }: {
    filter: IDataGridFilter,
    value: any,
    onTasks: any
  }
) => {
  const onChange = useCallback((value) => {
    onTasks([
      ['pagination', {page: 1}],
      ['filters', {[filter.field]: value.length ? value.map((val: any) => convertDateToRDateTime(val)) : []}],
      ['getData']
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const value_ = useMemo(() => {
    if (value && value.length) {
      return value.map((val: any) => convertRDateTimeToDate(val))
    } else {
      return [];
    }
  }, [value]);
  const {t} = useI18n();
  return <>
    <DateMultiple
      // @ts-ignore
      label={t(filter.options?.label)}
      // @ts-ignore
      placeholder={t(filter.options?.placeholder)}
      style={filter.options?.style}
      fullWidth={false}
      onChange={onChange}
      value={value_}/>
  </>
})
