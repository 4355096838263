import React, {memo, useContext, useMemo} from "react";
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar"
import {PickerView} from '@material-ui/pickers/Picker/Picker';
import {format} from "date-fns"
import {Button} from '@material-ui/core';
import {DateRangePickerContext} from "./date-range-picker"
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";

export const useStyles = makeStyles(
  {
    dateTitleLandscape: {
      margin: 'auto 16px auto auto',
    },
    year: {
      color: '#fff'
    },
    month: {
      color: '#fff'
    },
    controls: {
      display: 'flex',
      margin: '0 .5rem 1rem',
      justifyContent: 'center',
      '& > button': {
        marginRight: '1rem'
      }
    },
    root: {
      textAlign: 'center'
      // width: '100%'
    },
    title: {
      color: '#fff',
      fontWeight: 500,
      fontSize: '2rem'
    }
  },
  {name: 'MuiPickersDataRangeToolbar'}
);

export default memo((
  {
    date,
    isLandscape,
    setOpenView
  }: {
    date: MaterialUiPickersDate;
    setOpenView: (view: PickerView) => void;
    openView: string;
    isLandscape: boolean;
  }) => {
  const classes = useStyles();
  const context = useContext(DateRangePickerContext);
  const current = useMemo(() => {
    const current_ = date || new Date();
    return {year: format(current_, 'yyyy'), month: format(current_, 'MMMM')};
  }, [date]);

  const dateText = useMemo(() => {
    const dates_: any[] = [context.begin, context.end].sort((a, b) => a - b);
    return `${dates_[0] ? format(dates_[0], 'yyyy.MM.dd') : '---.--.--'} - ${dates_[1] ? format(dates_[1], 'yyyy.MM.dd') : '---.--.--'} `;
  }, [context]);

  return (
    <PickerToolbar
      isLandscape={isLandscape}
    >
      <div className={classes.root}>
        <div className={classes.controls}>
          <Button
            onClick={() => setOpenView('year')}
            className={classes.year}
            color="primary"
            size="small"
            variant="contained"
          >
            {current.year}
          </Button>
          <Button
            onClick={() => setOpenView('month')}
            className={classes.month}
            color="primary"
            size="small"
            variant="contained"
          >
            {current.month}
          </Button>
        </div>
        <Typography variant="h5" className={classes.title}>
          {dateText}
        </Typography>
      </div>
    </PickerToolbar>
  );
});
