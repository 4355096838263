import axios, {CancelTokenSource} from "axios";
import {apiStatic} from "../helpers";

export interface AurionCRGetRequest {
  apiEndpoint: string;
  cancelToken?: CancelTokenSource;
  params?: any;
}

export const getRequest = (
  {
    apiEndpoint,
    cancelToken,
    params
  }: AurionCRGetRequest
) => {
  return new Promise((resolve: (response: any) => void, reject: (error: any) => void) => {
    apiStatic(
      {
        method: 'get',
        url: `${apiEndpoint}/GetAllDynamic`,
        cancelToken: cancelToken?.token,
        params
      })
      .then(resolve)
      .catch(error => {
        if (!axios.isCancel(error)) reject(error)
      })
  })
};
