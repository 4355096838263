import React, {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import GridBody from "./body";
import {IDataGridMadel} from "../model";

interface ISwapState {
  startIndex: number | null
  endIndex: number | null
}

interface GridDndProviderProps {
  model: IDataGridMadel;
  data: any[];
  scroll: { x: number, y: number, '-x': number };
  onTasks: (tasks: any[]) => void;
  rowControls?: ReactNode;
  preview?: boolean
  pagination: any
  isHideSwap: boolean
}

export const GridDndProvider: React.FC<GridDndProviderProps> = ({data, ...rest}) => {
  const [{startIndex, endIndex}, _setSwap] = useState<ISwapState>({startIndex: null, endIndex: null})

  useEffect(() => {
    _setSwap({ startIndex: null, endIndex: null })
  }, [data])

  const setSwap = useCallback((v: Partial<ISwapState>) => {
    _setSwap((prev) => {
      return {...prev, ...v}
    })
  }, [])

  const dataSwap = useMemo(() => {
    if (startIndex !== null && endIndex !== null) {
      const copyData = [...data]
      const deleted = copyData.splice(startIndex, 1)
      copyData.splice(endIndex, 0, ...deleted)
      return copyData
    } else {
      return data
    }
  }, [startIndex, endIndex, data])

  const {onTasks} = rest
  const requestSwap = useCallback((p: ISwapState) => {
    onTasks([['swapData', {
      ...p,
      data
    }]])
  }, [onTasks, data])

  return (
    <GridBody {...rest} data={dataSwap} setSwap={setSwap} requestSwap={requestSwap} />
  )
}
