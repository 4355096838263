import React, {FC, createContext, useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {checkLogged, INITIAL_STATE} from "../store/modules/auth";
import {checkEs6AndRun} from "./helpers";

export const AuthContext = createContext(INITIAL_STATE());
/**
 * @desc
 * @param {Object} permissions - permissions for current user {isAllowEdit: true, isAllowView: false}
 * @param {String} key - list of fields => 'isAllowEdit, !isAllowView, ${data.id === value}'
 * @return {Boolean}
 */
export const permission = (permissions: any, key: string | undefined | null): boolean => {
  if (!permissions || !key) return true;
  let keys = key
    ? key.replace(/\s/g, '').split(',')
    : [];
  const result = keys.map(_ => false);
  keys.forEach((key_, i) => {
    if (/^\${/g.test(key_)) {
      result[i] = checkEs6AndRun(key_, permissions) === 'true';
    } else if (key_[0] === '!') {
      key_ = key_.slice(1);
      if (permissions[key_] !== undefined && !permissions[key_]) result[i] = true;
    } else {
      if (permissions[key_] !== undefined && permissions[key_]) result[i] = true;
    }
  });
  return !result.filter(item => !item).length;
};

export const useAuth = (): any => {
  const auth = useContext(AuthContext);
  return {
    permission(key: string | undefined | null) {
      // eslint-disable-next-line
      if (!auth.init && (auth.loading || !auth.user) || !auth.user) {
        return false;
      } else {
        return permission(auth.user.userPosition, key);
      }
    },
    ...auth
  }
};
export const AuthContextProvider: FC = ({children}) => {
  const auth = useSelector(state => (state as any).auth);
  // trigger auth
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(checkLogged());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <AuthContext.Provider value={{...auth}}>
      {children}
    </AuthContext.Provider>
  );
};
