import React, {FC, memo} from "react";
import {IDataGridMadel} from "../../model";
import Size from "./size";
import Pager from "./pager";

const Pagination: FC<{
  model: IDataGridMadel,
  pagination: any
  onTasks: any,
}> = ({model, pagination, onTasks}) => {
  if (!model || !model.pagination.visible) return null;
  return (
    <div className="data-grid-pagination">
      <div className="size">
        {model.pagination.sizes.visible &&
        <Size
          model={model}
          onTasks={onTasks}
          pagination={pagination}
        />
        }
      </div>
      <Pager
        pagination={pagination}
        onTasks={onTasks}
      />
    </div>
  )
};

export default memo(Pagination);
