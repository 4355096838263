import PagesMODS from "./pages";
import RedFlagsMODS from "./redFlags";

import PagesSaga from "./pages/saga";
import RedFlagsSaga from "./redFlags/saga";
import update from "immutability-helper";

export const addCaseRedFlag = (data: any, payload?: { pages?: any[], redFlags?: any[] }) => {
  return {
    [data.id]: {
      ...data,
      pages: payload?.pages || [],
      redFlags: payload?.redFlags || [],
      draggablePageID: '',
      draggableRedFlag: {id: '', page: -1, type: 1},
      newRedFlag: null,
      init: false,
      loading: false,
      requests: []
    }
  }
};

export const RedFlagsModifications = (state: any, action: any) => {
  const {type, payload} = action;

  let state_ = PagesMODS(state, action);
  state_ = RedFlagsMODS(state_, action);

  switch (type) {
    case 'FERedFlagRequests': {
      const {value, casePageID} = payload;
      if (value) {
        return update(state_, {cases: {[casePageID]: {requests: {$push: [1]}}}});
      } else {
        return update(state_, {cases: {[casePageID]: {requests: {$splice: [[0, 1]]}}}});
      }
    }
    case 'FERedFlagLoading':
      return update(state_, {cases: {[payload.casePageID]: {loading: {$set: (payload.value)}}}});
    default:
      return state_;
  }
};

export const RedFlagsSagas = [...PagesSaga, ...RedFlagsSaga];
