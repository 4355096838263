import React, {memo} from "react";
import {TimePickerProps, TimePicker as Picker} from "@material-ui/pickers";

export const TimePicker = memo((
  {
    error,
    format = 'hh:mma',
    ...props
  }: {
    error?: any | { message: string };
    format?: string;
    ampm?: boolean;
  } & TimePickerProps & any
) => {
  return <div className="date-field-wrapper">
    <Picker
      {...props}
      error={Boolean(error)}
      helperText={error ? error.message || '' : ''}
      format={format}
      onChange={(date: any) => {
        props.onChange({target: {value: date}});
      }}
      fullWidth
    />
  </div>
});

export default TimePicker;
