// mixins
import {reduce} from "lodash";
import {checkEs6AndRun, requestError, requestSuccess} from "../../../helpers";
import {IDataGridMadel} from "../../model";
import {notifyRequestResult} from "../../../../store/modules/notify";

export const parseData = (data: any) => {
  const {data_, mixins_} = reduce(data, (result: any, value, key) => {
    if (value && typeof value === 'object') {
      if (value.mixin_) {
        result.mixins_.push(value);
        if ([
          'imageUploader',
          'fileUploader',
          'editor'
        ].includes(value.type_)) result.data_[key] = '';
      }
    } else {
      result.data_[key] = value;
    }
    return result;
  }, {data_: {}, mixins_: []});
  return {data_, mixins_}
};
export const handleError = (error: any, type: string, onTasks: any, dispatch: any) => {
  if (onTasks) onTasks([
    ['onHandle', {type: type + 'Error', error: error}],
    [type + 'Data', null],
    ['loading', false]
  ]);
  dispatch(notifyRequestResult(requestError(error), 'error'));
};
export const handleDone = (response: any, type: string, onTasks: any, dispatch: any, payload: any[] = []) => {
  if (onTasks) onTasks([
    ['onHandle', {type: type + 'Done', response: response.data}],
    [type + 'Data', null],
    ...payload,
    ['getData']
  ]);
  if (dispatch) dispatch(notifyRequestResult(requestSuccess(response)));
};
export const createGetProps = (
  {
    model,
    pagination,
    filters,
    sortable
  }: {
    model: IDataGridMadel;
    pagination: any;
    filters: any;
    sortable: any;
  }) => {
  const props: any = {
    url: model.store.get.url,
    params: {
      'Count': true,
      'Select': model.store.get.selected
    }
  };
  //expand
  if (model.store.get.expand) {
    props.params['Expand'] = model.store.get.expand;
  }
  // pagination
  if (pagination.size !== 'all') {
    if (pagination.page - 1) props.params['Skip'] = (pagination.page - 1) * pagination.size;
    props.params['Take'] = pagination.size;
  }
  // sortable
  if (sortable.field && sortable.order) {
    const column: any = model.columns.filter(column => column.field === sortable.field)[0];
    if (column) {
      if (column.sortable) {
        if (typeof column.sortable === 'string') {
          props.params['OrderBy'] = `${column.sortable} ${sortable.order}`;
        } else if (column.sortable[sortable.order]) {
          props.params['OrderBy'] = `${column.sortable[sortable.order]} ${sortable.order}`;
        } else {
          props.params['OrderBy'] = `${sortable.field} ${sortable.order}`;
        }
      } else {
        props.params['OrderBy'] = `${sortable.field} ${sortable.order}`;
      }
    } else {
      props.params['OrderBy'] = `${sortable.field} ${sortable.order}`;
      // console.error(`Error parse sortable by request: Can\`t find column by field ${sortable.field}`)
    }
  }
  // filters
  if (Object.keys(filters).length || model.store.get.filters) {
    const mainFilters = (model.store.get.filters) ? [model.store.get.filters] : [];
    const toolbarFilters = reduce(filters, (result: any, value: any, field: any) => {
      const filter_ = model.toolbar.filtersMap[field];
      if (filter_) {
        if (
          Array.isArray(value) &&
          (filter_.options.type === 'select' && filter_.options.multiple)
        ) {
          result.push(`(${value
            .map((value: string | number) => checkEs6AndRun(filter_.options.valueTemplate, {field, value}))
            .join(' || ')
          })`);
        } else if (
          (value || value === false) &&
          // eslint-disable-next-line
          (!Array.isArray(value) || Array.isArray(value) && value.length)
        ) {
          result.push(checkEs6AndRun(filter_.options.valueTemplate, {field, value}));
        }
      } else {
        console.error('Error parse filters by request', field, model.id)
      }
      return result;
    }, []);
    const result_ = [...mainFilters, ...toolbarFilters].join(' && ');
    if (result_.length) props.params['Filter'] = result_;
  }
  return props;
};
