import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {Button, FormControl, FormHelperText, Input, InputLabel, Popover} from "@material-ui/core";
import {getRandomString} from "../helpers";
import {PopoverOrigin} from "@material-ui/core/Popover/Popover";
import {useI18n} from "../i18";
import Scrollbars from "react-custom-scrollbars";

export const SelectDropDown = memo((
  {
    value,
    source,
    onChange,
    label,
    render,
    placeholder = '',
    allowEmpty = false,
    option = {id: 'id', label: 'title'},
    anchorOrigin = {vertical: 'bottom', horizontal: 'left'},
    transformOrigin = {vertical: 'top', horizontal: 'left'},
    className = '',
    ...props
  }: {
    value: any;
    source: any;
    onChange: (e?: any, value?: any) => void;
    label?: string;
    render?: any;
    placeholder?: string;
    allowEmpty?: boolean;
    option?: { id: string, label: string };
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    className?: string;
  } & any) => {
  const {t} = useI18n();
  const [uid] = useState(getRandomString());
  const button = useRef<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const error = useMemo(() => Boolean(props.error), [props]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const errorMessage = useMemo(() => props.error ? t(props.error.message) || '' : '', [props]);
  const defOption = useMemo(() => {
    return {[option.id]: '', [option.label]: allowEmpty ? placeholder || t('empty') : ''};
  }, [placeholder, option, t, allowEmpty]);
  const source_ = useMemo(() => {
    if (allowEmpty) {
      return [defOption, ...source]
    } else {
      return [...source];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, placeholder, defOption]);
  const value_ = useMemo(() => {
    const def = {value: '', item: {...defOption}};
    if (value) {
      if (source_) {
        const item = source_.filter((item: any) => item[option.id] === value)[0];
        if (item) return {value: item[option.label], item: item};
      }
      return def;
    }
    return def;
  }, [value, source_, option, defOption]);
  // handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (props.autoFocus && button && button.current) button.current.click();
  }, [props.autoFocus, button]);
  return <FormControl error={error} className={`select-drop-down ${className || ''}`}>
    {label && <InputLabel htmlFor={props.name || uid}>{label}</InputLabel>}
    <div
      className={`MuiInputBase-root MuiInput-root MuiInput-underline${error ? ' Mui-error' : ''}${props.disabled ? ' Mui-disabled' : ''} MuiInputBase-formControl MuiInput-formControl`}>
      <Button
        ref={button}
        aria-controls={props.name || uid}
        aria-haspopup="true"
        disabled={Boolean(props.disabled)}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon/>}
      >
        {render
          ? render(value_.item, handleClose)
          : <span className="title">{value_.value ? value_.item[option.label] : allowEmpty ? placeholder || t('empty') : ''}</span>
        }
      </Button>
      <Popover
        id={props.name || uid}
        className={`select-drop-down_popper${className ? ` ${className}_drop-down_popper` : ''}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Scrollbars
          className="r-scroll-bar select-drop-down_popper-scroll"
          autoHide={false}
          autoHideTimeout={0}
          autoHideDuration={0}
          autoHeight
          autoHeightMax={350}
        >
          <div className="items">
            {source_ && source_.map((item: any) => <Button
              key={item[option.id]}
              // eslint-disable-next-line
              className={`${item[option.id] == value_.item[option.id] ? 'selected' : ''}${item.isGroup ? ' group' : ''}`}
              onClick={() => {
                // eslint-disable-next-line
                if (item[option.id] != value_.item[option.id]) onChange({target: {value: item[option.id]}}, item);
                handleClose();
              }}
            >
              {render
                ? render(item, handleClose)
                : item[option?.label]
              }
            </Button>)}
          </div>
        </Scrollbars>
      </Popover>
    </div>
    {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    <Input
      value={value_.value || allowEmpty ? placeholder || t('empty') : ''}
      style={{display: 'none'}}
    />
  </FormControl>;
});

export default SelectDropDown;
