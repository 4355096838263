type Join<K, P> = K extends string | number ?
  P extends string | number ?
    `${K}${"" extends P ? "" : "."}${P}`
    : never : never;

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]]

type Paths<T, D extends number = 3> = [D] extends [never] ? never : T extends object ?
  {
    [K in keyof T]-?: K extends string | number ?
    `${K}` | Join<K, Paths<T[K], Prev[D]>>
    : never
  }[keyof T] : ""

// type Paths<T> = any

const prepareValue = (value?: string) => {
  return String(value).toLowerCase().trim().replace(/"/gi, '\\"')
}
const createStart = (count: number, query: string) => {
  return count > 1 ? `(${query})` : query
}
export const createFilterEquals = <T extends object = {}>(name: Paths<T> | (Paths<T>)[], value: any) => {
  const names = Array.isArray(name) ? name : [name]

  return value
    ? createStart(names.length,
      names
        .map((_n) => `${_n}=="${prepareValue(value)}"`)
        .join('||')
    )
    : undefined
}

export const createFilterWith = <T extends Record<string, any>>(name: Paths<T> | (Paths<T>)[], value: boolean) => {
  const fields = Array.isArray(name) ? name : [name]
  return value
    ? fields.map((field) => `(${field} != "" && ${field} != null)`).join(' && ')
    : null
}

export const createFilterWithout = <T extends Record<string, any>>(name: Paths<T> | (Paths<T>)[], value: boolean) => {
  const fields = Array.isArray(name) ? name : [name]
  return value
    ? fields.map((field) => `(${field} == "" || ${field} == null)`).join(' && ')
    : null
}

export const createFilterContains = <T extends object = {}>(name: Paths<T> | (Paths<T>)[], value: any) => {
  const names = Array.isArray(name) ? name : [name]

  return value
    ? `(${
      names
        .map((_n) => `${_n}.ToLower().contains("${prepareValue(value)}")`)
        .join('||')
    })`
    : null
}

export const createFilterSmartSearch = <T extends object = {}>(name: Paths<T> | (Paths<T>)[], value: any) => {
  const names = Array.isArray(name) ? name : [name]
  if (!value) return null

  const field = names
    .map((_name) => `${_name}.ToLower().replace(" ",String.Empty)`)
    .join('+')
  return createFilterContains<T>(`(${field})` as Paths<T>, String(value).replace(/ /g, ''))
}
