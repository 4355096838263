import update from "immutability-helper";
import {iCaseMedicalCondition, iMedicalConditionGroup} from "./@types";
import {getTypeModel} from "../redFlag/redFlags/model";

export const addCaseMedicalConditionGroup = (data: any, payload?: { pages?: iMedicalConditionGroup[], pagesContent?: iCaseMedicalCondition[][] }) => {
  return {
    [data.id]: {
      ...data,
      pages: payload?.pages || [],
      pagesContent: payload?.pagesContent || [],
      draggablePageID: '',
      draggableMedicalCondition: {id: '', page: -1, type: 1},
      init: false,
      loading: false,
      requests: []
    }
  }
};

export const findMedicalCondition = (id: string, pagesContent: any[]) => {
  let medicalCondition;
  pagesContent.some((medicalConditions: any[], page: number) => {
    return medicalConditions.some((element: any, index: number) => {
      if (element.id === id) {
        medicalCondition = {data: element, index, page};
        return true;
      }
      return false;
    })
  });
  return medicalCondition;
};

export const MedicalConditionGroupState = (state: any, action: any) => {
  const {type, payload} = action;
  switch (type) {
    // LOADING
    case 'FECaseMedicalConditionGroupLoading':
      return update(state, {cases: {[payload.casePageID]: {loading: {$set: (payload.value)}}}});
    case 'FECaseMedicalConditionGroupRequests': {
      const {value, casePageID} = payload;
      if (value) {
        return update(state, {cases: {[casePageID]: {requests: {$push: [1]}}}});
      } else {
        return update(state, {cases: {[casePageID]: {requests: {$splice: [[0, 1]]}}}});
      }
    }
    // PAGES
    case 'FECaseMedicalConditionGroupPageAdd':
      return update(state, {
        cases: {
          [payload.casePageID]: {
            pages: {$push: [payload.data]},
            pagesContent: {$push: [[]]}
          }
        }
      });
    case 'FECaseMedicalConditionGroupPageRemove':
      return update(state, {
        cases: {
          [payload.casePageID]: {
            pages: {$splice: [[payload.index, 1]]},
            pagesContent: {$splice: [[payload.index, 1]]}
          }
        }
      });
    case 'FECaseMedicalConditionGroupPageUpdate':
      return update(state, {
        cases: {
          [payload.casePageID]: {
            pages: {[payload.index]: {$merge: payload.data}},
          }
        }
      });
    case 'FECaseMedicalConditionGroupPageDraggablePageID': {
      const {casePageID, id} = payload;
      return update(state, {cases: {[casePageID]: {draggablePageID: {$set: id}}}});
    }
    case 'FECaseMedicalConditionGroupPageMove': {
      const {casePageID, id, index} = payload;
      const {pages}: any = state.cases[casePageID];
      let page: any;
      pages.some((value: any, index: number) => {
        if (value.id === id) {
          page = {data: value, index};
          return true;
        }
        return false;
      });
      if (page) {
        const pagesContent = state.cases[casePageID].pagesContent[page.index];
        return update(state, {
          cases: {
            [casePageID]: {
              pages: {$splice: [[page.index, 1], [index, 0, page.data]]},
              pagesContent: {$splice: [[page.index, 1], [index, 0, pagesContent]]},
              draggablePageID: {$set: page.data.id}
            }
          }
        });
      } else {
        return state;
      }
    }
    // ITEMS
    case 'FECaseMedicalConditionRemove': {
      const {casePageID, page, index} = payload;
      return update(state, {cases: {[casePageID]: {pagesContent: {[page]: {$splice: [[index, 1]]}}}}});
    }
    case 'FECaseMedicalConditionUpdate': {
      const {id, casePageID, data, page, index} = payload;
      const {pagesContent} = state.cases[casePageID];
      if (page !== undefined && index !== undefined) {
        return update(state, {cases: {[casePageID]: {pagesContent: {[page]: {[index]: {$merge: data}}}}}});
      } else {
        const medicalCondition: any = findMedicalCondition(id, pagesContent);
        if (medicalCondition) {
          return update(state, {cases: {[casePageID]: {pagesContent: {[medicalCondition.page]: {[medicalCondition.index]: {$merge: data}}}}}});
        } else {
          return state;
        }
      }
    }
    case 'FECaseMedicalConditionAdd': {
      const {page, casePageID, data} = payload;
      return update(state, {
        cases: {[casePageID]: {pagesContent: {[page]: {$push: [data]}}}}
      });
    }
    case 'FECaseMedicalConditionDraggable': {
      const {casePageID, id, page} = payload;
      return update(state, {cases: {[casePageID]: {draggableMedicalCondition: {$set: {id, page}}}}});
    }
    case 'FECaseMedicalConditionMove': {
      const {casePageID, id, index, page} = payload;
      const {pagesContent} = state.cases[casePageID];
      const medicalCondition: any = findMedicalCondition(id, pagesContent);
      if (medicalCondition) {
        const state_ = update(state, {
          cases: {
            [casePageID]: {
              pagesContent: {[medicalCondition.page]: {$splice: [[medicalCondition.index, 1]]}}
            }
          }
        });
        return update(state_, {
          cases: {
            [casePageID]: {
              pagesContent: {[page]: {$splice: [[index, 0, medicalCondition.data]]}},
              draggableMedicalCondition: {$set: {id, page}}
            }
          }
        });
      }
    }
    default:
      return state;
  }
};

export * from './saga'
export * from './@types'