import {apiStatic, requestError} from "../helpers";
import axios from "axios";
import {notifyRequestResult} from "../../store/modules/notify";

export const replaceNull = (value: any) => {
  Object.keys(value).forEach(key => {
    if (value[key] === null) value[key] = '';
  });
  return value;
};
export const validateRequired = (data: any) => {
  return !data ? 'required' : undefined;
};
export const validateEmail = (data: string) => {
  if (data && data.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/gi) === null) {
    return 'invalid-email';
  } else {
    return undefined;
  }
};
export const validateURL = (data: string) => {
  // eslint-disable-next-line
  if (data && data.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g) === null) {
    return 'invalid-url';
  } else {
    return undefined;
  }
};
export const validateNumber = (data: string) => {
  let value = Number(data)
  if (data && isNaN(value)) {
    return 'invalid-number';
  } else {
    return undefined;
  }
}
export const validateRule = (rule = '') => {
  if (typeof rule === 'string') {
    return rule.split(',').reduce((result: any, key: string) => {
      switch (key) {
        case 'required':
          result['required'] = 'required';
          break;
        case 'requiredMixin':
          result['validate'] = (data: any) => {
            if (typeof data === 'object') {
              if (data.value && data.value.length) return undefined;
            } else if (data) {
              return undefined;
            }
            return 'required'
          };
          break;
        case 'minLength':
          result['minLength'] = {
            value: 2,
            message: 'length-min-error'
          };
          break;
        case 'email':
          result['validate'] = validateEmail;
          break;
        case 'url':
          result['validate'] = validateURL;
          break;
        case 'number':
          result['validate'] = validateNumber;
          break;
      }
      return result;
    }, {})
  } else {
    return undefined;
  }
};
export const getFormTitle = (itemID: any, data: any, newLabel = 'new', field = 'title') => {
  return itemID === true
    ? newLabel
    : data && data[field] !== undefined
      ? data[field]
      : 'loading'
};
// handlers
export const defaultOnGridHandle = ({payload, form, setEditItemID, isEditAfter = false, setIsEditAfter}: any) => {
  switch (payload.type) {
    case 'new':
      setEditItemID(true);
      break;
    case 'edit':
      setEditItemID(payload.id);
      break;
    case 'pathDone':
    case 'deleteDataDialogDone':
      setEditItemID(false);
      break;
    case 'createDone':
      setEditItemID(false);
      if (isEditAfter) {
        setTimeout(_ => {
          setEditItemID(payload.response.id);
          setIsEditAfter(false);
        }, 100);
      }
      break;
    case 'pathError':
    case 'createError':
      if (form) form.current.setLoading(false);
      break;
  }
};
export const defaultOnFormHandle = ({value, grid, setIsEditAfter, setEditItemID}: {
  value: { payload: { itemID: any, row: any, value: any }, type: string, isEditAfter?: boolean };
  grid: any;
  setIsEditAfter: any;
  setEditItemID: any;
}) => {
  if (grid) {
    const {type, payload, isEditAfter} = value;
    switch (type) {
      case 'submit':
        if (payload.itemID && payload.itemID !== true && payload.itemID !== 'true') {
          grid.current.onTasks([['pathData', {row: payload.row, value: payload.value}]])
        } else {
          if (isEditAfter) setIsEditAfter(true);
          grid.current.onTasks([['createData', payload.value]]);
        }
        break;
      case 'delete':
        grid.current.onTasks([['deleteData', payload]]);
        break;
      case 'closeFormAndGridRefresh':
        setEditItemID(false);
        grid?.current?.onTasks([['getData']]);
        break;
      case 'setEditItemIDAndGridRefresh': {
        if (payload.value) {
          setEditItemID(payload.value);
        }
        grid?.current?.onTasks([['getData']]);
        break;
      }
    }
  }
};
export const defaultFormGetData = (
  method: string,
  {
    itemID,
    defaultValues,
    customSelect = [],
    setLoading,
    setData,
    reset,
    dispatch,
    cancelToken,
    onCameEmpty = () => {}
  }: any, fieldID = 'id') => {
  setLoading(true);
  apiStatic({
    method: 'get',
    url: `${method}/GetAllDynamic`,
    cancelToken: cancelToken.token,
    params: {
      'Select': [...Object.keys(defaultValues()), fieldID, ...customSelect].toString(),
      'Filter': `id == "${itemID}"`
    }
  })
    .then(response => {
      const isHasData = !!response.data.value[0]
      const data = isHasData
        ? replaceNull(response.data.value[0])
        : defaultValues()
        setData(data);
        reset(data);
        setLoading(false);

      if (!isHasData) {
        onCameEmpty()
      }
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        setLoading(false);
        dispatch(notifyRequestResult(requestError(error), 'error'));
        console.error(error);
      }
    })
};
