export const SET_LANGUAGE = 'SET_LANGUAGE';

export default (state: any = {
  language: null,
  languageID: '',
  direction: 1,
  isRTL: false,
}, action: any) => {
  switch (action.type) {
    case SET_LANGUAGE : {
      const result = {
        ...state,
        ...action.payload,
      };
      result.isRTL = result.direction === 2;
      document.body.setAttribute('dir', result.direction === 1 ? 'ltr' : 'rtl');
      return result;
    }
    default:
      return state
  }
}

export const setLanguage = (languageID: number | string, language?: any, direction?: number) => ({
  type: SET_LANGUAGE,
  payload: {languageID, language, direction: direction || 1}
});
