import React, { useEffect } from 'react';
import { api } from 'AurionCR/components';
import { useDispatch } from 'react-redux';

interface AxiosInterceptorsSourcesProps {
}

export const AxiosInterceptorsSources: React.FC<AxiosInterceptorsSourcesProps> = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const ids = [api]
      .map((instance) => {
        const id = instance.interceptors.response.use((response) => {
          const { method, baseURL = '', url = '' } = response.config;
          if (method !== 'get') {
            dispatch({
              type: 'SOURCES_UPDATE',
              path: baseURL.length && url.indexOf(baseURL) !== -1 ? url.slice(baseURL?.length) : url,
            });
          }
          return response;
        });
        return { id, instance };
      });

    return () => {
      ids
        .forEach(({ instance, id }) => {
          instance.interceptors.request.eject(id);
        });
    };
  }, [dispatch]);

  return <>{children}</>;
};
