// stores
import auth from "AurionCR/store/modules/auth";
import notify from "AurionCR/store/modules/notify";
import routes from "AurionCR/store/modules/routes";
import sources from "AurionCR/store/modules/sources";
import flowEditor from "../pages/FlowEditor/Flow/editor/store";
// sagas
import authSaga from './auth'
import sourceSaga from 'AurionCR/store/sagas/source'
import flowEditorSaga from "../pages/FlowEditor/Flow/editor/store/saga";

// custom
import {language, languageSaga} from "./language";

export default {
  store: {
    auth,
    notify,
    routes,
    sources,
    language,
    flowEditor
  },
  sagas: [
    ...authSaga,
    ...languageSaga,
    ...sourceSaga,
    ...flowEditorSaga
  ]
}
