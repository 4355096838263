import update from "immutability-helper";

export const findRedFlagOption = (id: string, data: any[]) => {
  let result;
  data.some((option: any, index: number) => {
    if (option.id === id) {
      result = {data: option, index};
      return true;
    }
    return false;
  });
  return result;
};

export default (state: any, action: { type: string, payload?: any }) => {
  const {type, payload} = action;
  switch (type) {
    case 'FERedFlagOptionRemove': {
      const {casePageID, pageIndex, redFlagIndex, index} = payload;
      return update(state, {
        cases: {
          [casePageID]: {
            redFlags: {
              [pageIndex]: {
                [redFlagIndex]: {
                  options: {
                    data: {$splice: [[index, 1]]}
                  }
                }
              }
            }
          }
        }
      });
    }
    case 'FERedFlagOptionsUpdate': {
      const {casePageID, pageIndex, redFlagIndex, data} = payload;
      return update(state, {
        cases: {
          [casePageID]: {
            redFlags: {
              [pageIndex]: {
                [redFlagIndex]: {
                  options: {$merge: data}
                }
              }
            }
          }
        }
      });
    }
    case 'FERedFlagOptionUpdate': {
      const {casePageID, pageIndex, redFlagIndex, index, data} = payload;
      return update(state, {
        cases: {
          [casePageID]: {
            redFlags: {
              [pageIndex]: {
                [redFlagIndex]: {
                  options: {
                    data: {[index]: {$merge: data}}
                  }
                }
              }
            }
          }
        }
      });
    }
    case 'FERedFlagOptionAdd': {
      const {casePageID, pageIndex, redFlagIndex, data} = payload;
      return update(state, {
        cases: {
          [casePageID]: {
            redFlags: {
              [pageIndex]: {
                [redFlagIndex]: {
                  options: {
                    data: {$push: [data]}
                  }
                }
              }
            }
          }
        }
      });
    }
    case 'FERedFlagOptionMove': {
      const {casePageID, pageIndex, redFlagIndex, index, id} = payload;
      const option: any = findRedFlagOption(id, state.cases[casePageID].redFlags[pageIndex][redFlagIndex].options.data);
      if (option) {
        return update(state, {
          cases: {
            [casePageID]: {
              redFlags: {
                [pageIndex]: {
                  [redFlagIndex]: {
                    options: {
                      data: {$splice: [[option.index, 1], [index, 0, option.data]]},
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return state;
      }
    }
    default:
      return state
  }
};
