import React, {memo, useMemo} from "react";
import {Dialog, DialogTitle, DialogActions, Button} from "@material-ui/core";
import {es6Run} from "../../helpers";
import {useI18n} from "../../i18";

const DeleteDialog = memo((
  {
    title,
    field,
    data,
    onTasks
  }: {
    title: ((data: any) => void) | string;
    field: string;
    data: any;
    onTasks: (tasks: any[]) => void
  }
) => {
  const {t, labels} = useI18n();
  const dialogTitle = useMemo(() => {
    const title_ = t('delete-item');
    if (typeof title === 'function') {
      return (
        <>
          {`${title_} `}
          {title(data)}
        </>
      )
    } else if (title_.indexOf("{0}") !== -1) {
      const parts_ = title_.split('"{0}"');
      return <>
        {parts_[0]}
        <strong>{data[field]}</strong>
        {parts_[1]}
      </>;
    } else if (title) {
      return `${title_} "${es6Run(data, title)}"`;
    } else {
      return `${title_} "${es6Run(data, field)}"`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, field, title, labels]);
  return (
    <Dialog
      open={true}
      disableBackdropClick
      disableEscapeKeyDown
      className="data-grid-delete-dialog"
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogActions>
        <Button
          onClick={_ => onTasks([
            ['onHandle', {type: 'deleteDataDialogCancel', data: data}],
            ['deleteData', null]
          ])}
          color="primary"
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={_ => onTasks([
            ['onHandle', {type: 'deleteDataDialogDone', data: data}],
            ['deleteDataRequest', {...data}],
            ['deleteData', null]
          ])}
          color="primary"
        >
          {t('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
});

export default DeleteDialog
