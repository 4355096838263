import {put, call, takeLatest} from 'redux-saga/effects'
import * as actions from "AurionCR/store/modules/auth"
import {notifyRequestResult} from "AurionCR/store/modules/notify";
import {api, requestError} from "AurionCR/components/helpers"
import {updateTokenAll} from "AurionCR/components";
import {sagaErrorHandler} from "./index";

export const ERROR_AS_SUCCESS_GENERATE = 'already-generated';

function* getUser() {
  yield put(actions.authStart());
  try {
    // @ts-ignore
    let {data} = yield call(api.get, 'Accounts/GetCurrentAppUser');
    if (data.languageID) yield put({type: 'LANGUAGE_SET_LANG', payload: {languageID: data.languageID}});
    yield put(actions.authSuccess({...data, userPosition: data.userCrmProfilePermission}));
  } catch (error) {
    // TODO: if Error Axios try update Token
    yield put(actions.checkLoggedFail());
    return error;
  }
}

function* authUser(action: any) {
  yield put(actions.authStart());
  try {
    const {cookie, ...data} = action.data;
    // @ts-ignore
    let response = yield call(api.post, 'Accounts/LoginWithCode', data);
    if (response.status === 200) {
      const {jwt: {token, refreshToken}, expires} = response.data;
      try {
        updateTokenAll({token, refreshToken, expires});
        yield call(getUser);
      } catch (error) {
        // @ts-ignore
        const errorMessage = yield call(sagaErrorHandler, error);
        yield put(actions.authFail(errorMessage));
      }
    } else {
      // @ts-ignore
      const errorMessage = yield call(sagaErrorHandler, response);
      yield put(actions.authFail(errorMessage));
    }
  } catch (error) {
    // @ts-ignore
    const errorMessage = yield call(sagaErrorHandler, error);
    yield put(actions.authFail(errorMessage));
  }
}

// @ts-ignore
function* authCode(action: any) {
  yield put(actions.authStart());
  try {
    yield call(api.post, 'Accounts/GeneratePassword', action.data);
    yield put(actions.authCodeSuccess());
  } catch (error) {
    if (requestError(error) === ERROR_AS_SUCCESS_GENERATE) {
      yield put(actions.authFail(requestError(error)));
    } else {
      // @ts-ignore
      const errorMessage = yield call(sagaErrorHandler, error);
      yield put(actions.authFail(errorMessage));
    }
  }
}

function* logout() {
  yield put(actions.authStart());
  try {
    yield call(api.post, 'Accounts/Logout');
    yield put(actions.logoutSucceed());
    updateTokenAll();
  } catch (error) {
    const errorMessage = requestError(error);
    yield put(notifyRequestResult(errorMessage, 'error'));
    yield put(actions.authFail(errorMessage));
  }
}

export default [
  takeLatest('AUTH_GET_USER', getUser),
  takeLatest('AUTH_USER', authUser),
  takeLatest('AUTH_CODE', authCode),
  takeLatest('AUTH_INITIATE_LOGOUT', logout),
];
