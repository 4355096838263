import update from "immutability-helper";

export default (state: any, action: { type: string, payload?: any }) => {
  const {type, payload} = action;
  switch (type) {
    case 'FERedFlagPageRemove':
      return update(state, {
        cases: {
          [payload.casePageID]: {
            pages: {$splice: [[payload.index, 1]]},
            redFlags: {$splice: [[payload.index, 1]]}
          }
        }
      });
    case 'FERedFlagPageUpdate':
      return update(state, {
        cases: {
          [payload.casePageID]: {
            pages: {[payload.index]: {$merge: payload.data}},
          }
        }
      });
    case 'FERedFlagPageAdd':
      return update(state, {
        cases: {
          [payload.casePageID]: {
            pages: {$push: [payload.data]},
            redFlags: {$push: [[]]}
          }
        }
      });
    case 'FERedFlagPageDraggablePageID': {
      const {casePageID, id} = payload;
      return update(state, {cases: {[casePageID]: {draggablePageID: {$set: id}}}});
    }
    case 'FERedFlagPageMove': {
      const {casePageID, id, index} = payload;
      const {pages} = state.cases[casePageID];
      let page: any;
      pages.some((value: any, index: number) => {
        if (value.id === id) {
          page = {data: value, index};
          return true;
        }
        return false;
      });
      if (page) {
        const redFlags = state.cases[casePageID].redFlags[page.index];
        return update(state, {
          cases: {
            [casePageID]: {
              pages: {$splice: [[page.index, 1], [index, 0, page.data]]},
              redFlags: {$splice: [[page.index, 1], [index, 0, redFlags]]},
              draggablePageID: {$set: page.data.id}
            }
          }
        });
      } else {
        return state;
      }
    }
    default:
      return state
  }
};
