export const redFlagModel = () => ({
  redFlagPageID: '',
  redFlagTypeID: '',
  catalogName: '',
  orderNumber: 0,
  genderID: null,
  defaultIsNo: null,
  defaultIsYes: null,
  referOnNo: null,
  referOnYes: null,
});
export const getTypeModel = (type: number) => {
  switch (type) {
    case 1:
      return {
        ...redFlagModel(),
        // additions
        open: false,
        // types
        redFlagType: 1,
        redFlagTypeTitle: '',
      };
    case 2:
      return {
        ...redFlagModel(),
        // additions
        open: false,
        options: {
          init: false,
          open: false,
          loading: false,
          draggableID: '',
          data: []
        },
        // types
        redFlagType: 2,
        redFlagTypeTitle: '',
      };
    case 3:
      return {
        ...redFlagModel(),
        // additions
        open: false,
        options: {
          init: false,
          open: false,
          loading: false,
          draggableID: '',
          data: []
        },
        // types
        redFlagType: 3,
        redFlagTypeTitle: '',
      };
  }
};

export const RedFlagOptionModel = () => ({
  catalogName: '',
  orderNumber: 0,
  score: 0,
  color: '#ffffff'
});
