import {IDataGridMadel} from "../../model";
import {apiStatic, api, checkEs6AndRun, requestError} from "../../../helpers";
import {notifyRequestResult} from "../../../../store/modules/notify";
import {parseData, createGetProps, handleDone, handleError} from "./helpers"
import {updateMixin} from "./mixins"
import axios from "axios";

// helpers
// GET
export const requestGet = (
  {model, pagination, filters, sortable, onTasks, dispatch, cancelToken}: {
    model: IDataGridMadel,
    pagination: any;
    sortable: any;
    filters: any;
    onTasks: any;
    dispatch: any;
    cancelToken?: any
  }) => {
  const params = createGetProps({model, pagination, filters, sortable});
  onTasks([
    ['onHandle', {type: 'getStart', params: {...params}}],
    ['loading', true]
  ]);
  apiStatic
    .request({...params, method: 'get', cancelToken: cancelToken ? cancelToken.token : undefined})
    .then(response => {
      onTasks([
        ['onHandle', {type: 'getDone', data: {...response.data}}],
        ['data', model.store.get.parser ? model.store.get.parser(response.data.value) : response.data.value],
        ['pagination', {total: response.data.count}],
        ['loading', false]
      ]);
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        onTasks([
          ['onHandle', {type: 'getError', error: error}],
          ['loading', false]
        ]);
        dispatch(notifyRequestResult(requestError(error), 'error'));
        console.error(error);
      }
    })
};
// PATH
export const requestPath = ({url, method, data, onTasks, dispatch, showResponseDone}: {
  url: string;
  method: 'patch' | 'put';
  data: { itemID?: string | number, row: any, value: any, isAutoSave: boolean } & any;
  onTasks?: any;
  dispatch: any;
  showResponseDone?: boolean;
}) => {
  if (onTasks && !data.isAutoSave) onTasks([
    ['onHandle', {type: 'pathStart', data: data}],
    ['loading', true]
  ]);

  const {data_, mixins_}: { data_: any, mixins_: any } = parseData(method === 'patch'
    ? data.value
    : {...data.row, ...data.value});
  api
    .request({
      method: method,
      url: checkEs6AndRun(url, data.row),
      data: data_
    })
    .then(response => {
      updateMixin(mixins_, data.row)
        .then(() => {
          if (!data.isAutoSave) handleDone(response, 'path', onTasks, showResponseDone ? dispatch : null)
        })
        .catch(error => {
          handleError(error, 'path', onTasks, dispatch);
          console.error(error)
        })
    })
    .catch(error => {
      handleError(error, 'path', onTasks, dispatch)
      console.error(error)
    })
};
// CREATE
export const requestCreate = ({url, mainField, data, onTasks, dispatch}: {
  url: string;
  mainField: string;
  data: any;
  onTasks: any;
  dispatch: any;
}) => {
  onTasks([
    ['onHandle', {type: 'createStart', data: data}],
    ['loading', true]
  ]);
  const {data_, mixins_}: { data_: any, mixins_: any } = parseData(data);
  api
    .request({
      method: 'post',
      url: checkEs6AndRun(url, data_),
      data: data_
    })
    .then(response => {
      const responseData_ = typeof response.data === 'object'
        ? response.data
        : {[mainField]: response.data};
      updateMixin(mixins_, responseData_)
        .then(() => handleDone(response, 'create', onTasks, dispatch))
        .catch(error => {
          handleError(error, 'create', onTasks, dispatch)
          console.error(error)
        })
    })
    .catch(error => {
      handleError(error, 'create', onTasks, dispatch)
      console.error(error)
    })
};
// DELETE
export const requestDelete = ({url, pagination, onTasks, dispatch, data}: {
  url: string;
  pagination: any;
  onTasks: any;
  dispatch: any;
  data: any;
}) => {
  onTasks([
    ['onHandle', {type: 'deleteStart', data: data}],
    ['loading', true]
  ]);
  api
    .request({
      method: 'delete',
      url: checkEs6AndRun(url, data)
    })
    .then(response => {
      const {page, size, total_} = pagination;
      // page:
      handleDone(response,
        'delete',
        onTasks,
        dispatch,
        // check to delete last item in page
        [['pagination', {page: ((page - 1) * size >= total_ - 1) ? Math.min(1, page - 1) : page}]]
      )
    })
    .catch(error => {
      handleError(error, 'delete', onTasks, dispatch);
      console.error(error)
    })
};
