import {api} from "../helpers";
import {parseFormData, saveMixinsFormData} from "./helpers";

export interface AurionCRCPatchRequest {
  formData: any;
  rowData: any;
  apiEndpoint: string;
  apiEndpointMainField?: string;
}

export const pathRequest = async (
  {
    formData,
    rowData,
    apiEndpoint,
    apiEndpointMainField = 'id',
  }: AurionCRCPatchRequest
) => {
  const {data, mixins} = parseFormData(formData);
  await api({method: 'patch', url: `${apiEndpoint}/Patch/${rowData[apiEndpointMainField]}`, data});
  if (mixins.length) await saveMixinsFormData(mixins, rowData);
  return rowData;
};
