import React, {createContext, FC, useCallback, useContext, useEffect, useState} from "react";

export const ResponseContext = createContext({width: 0, isTable: false, isMobile: false});
export const useResponsive = () => useContext(ResponseContext);

export const ResponsiveProvider: FC = (({children}: any) => {
  const [isMobile, setIsMobile] = useState<any>(false);
  const [isTable, setIsTable] = useState(false);
  const [width, setWidth] = useState(0);
  // handlers
  const onResize = useCallback(() => {
    setWidth(window.innerWidth);
    setIsTable(window.innerWidth < 769);
    setIsMobile(window.innerWidth < 641);
  }, [setWidth, setIsTable, setIsMobile]);
  // init
  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (window.navigator.appVersion.indexOf('Win') !== -1) {
    // @ts-ignore
    document.getElementById('root').classList.add('windows');
  }
  // render
  return <ResponseContext.Provider value={{width, isMobile, isTable}}>
    {children}
  </ResponseContext.Provider>
});
